import { DropdownStylesType } from '../types/bottom-actions.types';

export const darkDropdownStyles: DropdownStylesType = {
  indicatorSeparator: () => ({ display: 'none' }),
  control: (baseStyles) => ({
    ...baseStyles,
    background: '#363636',
    border: '0.5px solid #242424',
    width: '100%',
    color: '#EEEEEE',
    boxShadow: 'none',
    ':hover': {
      border: '0.5px solid #242424',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'initial',
    color: '#EEEEEE',
  }),
  menu: () => ({
    background: '#363636',
    position: 'absolute',
    width: '100%',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#ffffff',
    };
  },
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    position: 'relative',
    backgroundColor: isSelected || isFocused ? '#EAECF0' : undefined,
    paddingRight: 32,
    margin: 6,
    borderRadius: 6,
    width: '100%',
    color: '#EEEEEE',
    background: '#363636',
    textOverflow: 'ellipsis',
    maxWidth: '97%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ':hover': {
      background: isSelected ? '#363636' : '#ffffff',
      color: isSelected ? '#EEEEEE' : '#000000',
      cursor: isSelected ? 'default' : 'pointer',
    },

    ...(isSelected
      ? {
          ':after': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 10,
            margin: 'auto',
            background:
              'center no-repeat url(/static/img/icons/simple-white-tick.svg)',
            content: '" "',
            display: 'block',
            height: 14,
            width: 14,
          },
        }
      : {}),
  }),
};

export const SUGGESTIONS_ARRAY = [
  {
    id: 0,
    message: 'Design a wedding card for dinosaurs in formal wear',
  },
  {
    id: 1,
    message: 'Design an Instagram post for a missing dog like a pokemon',
  },
  {
    id: 2,
    message:
      'Design a Youtube Channel Banner for my beauty and skincare influencer brand',
  },
  {
    id: 3,
    message: 'Design an Instagram post for an online marketing course',
  },
  {
    id: 4,
    message: 'Design a Twitter Ad Card for a crypto-currency course',
  },
  {
    id: 5,
    message: 'Design an Instagram Story for an online tutoring business',
  },
  {
    id: 6,
    message: 'Design an Instagram Post Landscape for a new sportswear line',
  },
  {
    id: 7,
    message: 'Design a birthday card for an online gaming friend',
  },
  {
    id: 8,
    message:
      'Design an Instagram post for celebrating 100,000 video views on Youtube',
  },
  {
    id: 9,
    message:
      'Design an Instagram post for celebrating a new feature for a SaaS product',
  },
];

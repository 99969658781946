import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// api
import {
  createPromptToDesign,
  createPromptToDesignRecommendations,
  generatePromptToDesign,
  getPromptToDesign,
  getStudioPromptToDesignStatus,
} from '../../../api/helpers';

// helpers
import { SUGGESTIONS_ARRAY } from '../../../helpers/common.data';

// types
import {
  IPromptToDesignContent,
  RecommendationType,
} from '../../../types/studio.types';

// styling
import '../../../styles/studio/prompt-to-design.scss';

interface IStudioPromptToDesignForm {
  p2d: string;
}

const RECOMMENDATIONS_PLACEHOLDERS = [
  {
    id: 0,
    preview: '',
  },
  {
    id: 1,
    preview: '',
  },
  {
    id: 2,
    preview: '',
  },
];

const PromptToDesignContent = ({
  p2dStage,
  onSetP2dStage,
}: IPromptToDesignContent) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [recommendations, setRecommendations] = useState(
    RECOMMENDATIONS_PLACEHOLDERS,
  );
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<null | RecommendationType>({
      id: 0,
      preview: '',
    });
  const [generatedP2dId, setGeneratedP2dId] = useState();
  const [p2dError, setP2dError] = useState('');
  const [p2dId, setP2dId] = useState<null | number>(null);

  const inputContainerRef = useRef(null);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useForm<IStudioPromptToDesignForm>();

  const onInputFocus = () => {
    setIsInputFocused(true);
  };

  const onInputBlur = () => {
    setIsInputFocused(false);
  };

  const onP2dRandomize = () => {
    if (inputContainerRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (inputContainerRef.current as any).childNodes[0].focus();
    }
    setIsInputFocused(true);
    setValue('p2d', SUGGESTIONS_ARRAY[Math.floor(Math.random() * 10)].message, {
      shouldValidate: true,
    });
  };

  const onCreatePromptToDesign = async (purpose: string) => {
    let createP2DResponse = null;
    onSetP2dStage(1.5);

    try {
      if (p2dStage === 1) {
        createP2DResponse = await createPromptToDesign({
          purpose,
        });
        setP2dId(createP2DResponse.id);
      }

      setGeneratedP2dId(createP2DResponse?.id || p2dId);
      createPromptToDesignRecommendations(createP2DResponse?.id || p2dId, {
        purpose,
      }).then((innerRecommendations) => {
        setRecommendations(innerRecommendations.templates);
        onSetP2dStage(2);
      });
    } catch (error) {
      setP2dError(
        'Encountered an error during Prompt-to-Design, please try again later.',
      );
    }
  };

  const onFormSubmit = (data: IStudioPromptToDesignForm) => {
    setRecommendations(RECOMMENDATIONS_PLACEHOLDERS);
    onCreatePromptToDesign(data.p2d);
  };

  const onGeneratePromptToDesign = async (id: number) => {
    const response = await getPromptToDesign(id);

    if (response.status == 'failed') {
      setP2dError(
        'Encountered an error during Prompt-to-Design, please try again later.',
      );
      onSetP2dStage(1);
    } else if (response.status === 'created') {
      // ignore for now
    } else if (response.status === 'in_progress') {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await onGeneratePromptToDesign(id);
    } else if (response.status === 'finished') {
      navigate({
        pathname: `/tool/scene/${response.userDigitalGood}/`,
        search: `?origin_type=p2d&p2dId=${response.id}&redirectedFromStudio=1`,
      });

      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  const onSelectRecommendation = async (recommendation: RecommendationType) => {
    onSetP2dStage(2.5);
    setSelectedRecommendation(recommendation);

    try {
      const generatePromptToDesignData = { template: recommendation.id };
      await generatePromptToDesign(generatedP2dId, generatePromptToDesignData);

      await getStudioPromptToDesignStatus(generatedP2dId);
      onGeneratePromptToDesign(generatedP2dId as unknown as number);
    } catch (error) {
      setP2dError(
        'Encountered an error during Prompt-to-Design, please try again later.',
      );
    }
  };

  // TODO:Ros - apply regex to match inputMatchP2dFormat. format - (Design a x for a y)
  const inputMatchP2dFormat = watch('p2d');
  const isP2DListDisabled = p2dStage === 1.5 || p2dStage === 2.5;
  const generateBtnTitle = p2dStage === 1 ? 'start' : 'loading...';

  return (
    <div className="p2d-container--content">
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="p2d-container--content form"
      >
        <div className="p2d-container--content input-control">
          <div className="p2d-container--content--input-container">
            <div
              className={cn(
                'p2d-container--content--input-container--randomize-btn',
                {
                  disabled: isP2DListDisabled,
                },
              )}
              onClick={onP2dRandomize}
            >
              <img
                src="../../../../static/img/icons/randomize-p2d.svg"
                alt="randomize"
              />
            </div>
            <div className="input-inner-container" ref={inputContainerRef}>
              <input
                placeholder="Write a prompt to create your design"
                className={cn(
                  'p2d-container--content--input-container--field',
                  {
                    focused: isInputFocused,
                    disabled: isP2DListDisabled,
                  },
                )}
                {...register('p2d', {
                  required: true,
                  onChange: () => {
                    clearErrors();
                  },
                })}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                maxLength={150}
              />
            </div>
            <img
              src="../../../../static/img/icons/enter-icon.svg"
              alt="enter"
              className={cn(
                'p2d-container--content--input-container--field--icon',
                {
                  visible: isInputFocused,
                },
              )}
            />
          </div>
          {errors?.p2d && (
            <p className="p2d-container--content input-error-msg">
              Field is required
            </p>
          )}
        </div>

        {p2dStage === 2.5 && selectedRecommendation && (
          <div className="p2d-container--content--single-recommendation">
            <div className="p2d-container--content--single-recommendation--item active animate-pulse">
              <div className="p2d-container--content--single-recommendation--item--image-container active">
                <img
                  src={selectedRecommendation.preview}
                  alt="recommendation"
                  className="p2d-container--content--single-recommendation--item--image-container--image active"
                />
              </div>
              <div className="p2d-container--content--single-recommendation--item--btn-container active">
                <button
                  type="button"
                  className="p2d-container--content--single-recommendation--item--btn-container--btn active"
                >
                  <img src="/static/img/icons/star-studio.svg" alt="star" />
                  loading ...
                </button>
              </div>
            </div>
          </div>
        )}

        {p2dStage !== 1 && p2dStage !== 2.5 && (
          <div className="p2d-container--content--generation-container">
            {recommendations.map((recommendation) => {
              return (
                <div
                  className={cn(
                    'p2d-container--content--generation-container--item',
                    {
                      active: p2dStage === 2,
                    },
                  )}
                  key={recommendation.id}
                >
                  <div
                    className={cn(
                      'p2d-container--content--generation-container--item--image-container',
                      {
                        skeleton: p2dStage !== 2,
                        active: p2dStage === 2,
                      },
                    )}
                  >
                    {recommendation.preview && (
                      <img
                        src={recommendation.preview}
                        alt="recommendation"
                        className={cn(
                          'p2d-container--content--generation-container--item--image-container--image',
                          {
                            active: p2dStage === 2,
                          },
                        )}
                      />
                    )}
                  </div>
                  <div
                    className={cn(
                      'p2d-container--content--generation-container--item--btn-container',
                      {
                        active: p2dStage === 2,
                      },
                    )}
                  >
                    <button
                      type="button"
                      className={cn(
                        'p2d-container--content--generation-container--item--btn-container--btn',
                        {
                          skeleton: p2dStage !== 2,
                          active: p2dStage === 2,
                        },
                      )}
                      onClick={() => onSelectRecommendation(recommendation)}
                    >
                      {p2dStage === 2 && (
                        <img
                          src="/static/img/icons/star-studio.svg"
                          alt="star"
                        />
                      )}
                      {p2dStage === 2 ? 'select' : ''}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {p2dStage !== 2 && p2dStage !== 2.5 && (
          <div className="p2d-container--content--actions-container">
            <button
              type="submit"
              className={cn(
                'p2d-container--content--actions-container--submit-btn',
                {
                  disabled: !inputMatchP2dFormat || p2dStage === 1.5,
                },
              )}
            >
              {generateBtnTitle}
            </button>
          </div>
        )}
        {p2dStage === 2 && (
          <div className="p2d-container--content--try-generative-design-container">
            {/* className="p2d-container--content--try-generative-design-container--left-container" */}
            <div>
              {/* <img
                src="../../../../static/img/icons/double-star.svg"
                alt="stars"
              />
              <p className="p2d-container--content--try-generative-design-container--left-container--title">
                want more options?
              </p>
              <img
                src="../../../../static/img/icons/double-star.svg"
                alt="stars"
              /> */}
              <a
                href="/pricing"
                className="p2d-container--content--try-generative-design-container--left-container--btn"
                target="_blank"
              >
                try generative design
              </a>
            </div>
            <div className="p2d-container--content--try-generative-design-container--right-container">
              <button
                type="submit"
                className="p2d-container--content--try-generative-design-container--right-container--btn"
              >
                <img
                  src="../../../../static/img/icons/reload.svg"
                  alt="reload"
                />
                <span>restart</span>
              </button>
            </div>
          </div>
        )}
      </form>
      {p2dError && <p className="p2d-container--content--error">{p2dError}</p>}
    </div>
  );
};

export default PromptToDesignContent;

import React from 'react';
import cn from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';

// styles
import '../../styles/studio/studio-sidebar.scss';

// types
import {
  IStudioSidebar,
  IStudioSidebarElement,
} from '../../types/studio.types';

const SidebarElement = ({
  sidebarElement,
  sidebarKey,
  handleSelectSidebarElement,
  selectedSidebarElementId,
}: IStudioSidebarElement) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const folderId = searchParams.get('folderId');

  return (
    <div
      className={cn('studio-sidebar-container--element', {
        active: selectedSidebarElementId === +sidebarKey,
      })}
      id={sidebarElement?.title.split(' ').join('-')}
      onClick={() => {
        handleSelectSidebarElement(+sidebarKey);

        if (sidebarElement?.title === 'projects' && folderId) {
          return;
        }

        navigate({
          pathname: '/tool/studio/',
        });
      }}
    >
      {sidebarElement?.icon(
        selectedSidebarElementId === +sidebarKey ? '#eeeeee' : '#bcbcbc',
      )}
      <p>{sidebarElement?.title}</p>
    </div>
  );
};

const StudioSidebar = ({
  selectedSidebarElementId,
  handleSelectSidebarElement,
  sidebarData,
}: IStudioSidebar) => {
  return (
    <div className="studio-sidebar-container">
      {sidebarData &&
        sidebarData.map((sidebarElement) => (
          <SidebarElement
            key={sidebarElement.id}
            sidebarElement={sidebarElement}
            sidebarKey={sidebarElement.id}
            handleSelectSidebarElement={handleSelectSidebarElement}
            selectedSidebarElementId={selectedSidebarElementId}
          />
        ))}
    </div>
  );
};

export default StudioSidebar;

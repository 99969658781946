import React from 'react';

// components
import ProjectsTopbar from '../Projects/ProjectsTopbar';
import ProjectsContent from '../Projects/ProjectsContent';

const StudioProjects = () => {
  return (
    <div>
      <ProjectsTopbar />
      <ProjectsContent />
    </div>
  );
};

export default StudioProjects;

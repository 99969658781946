import React from 'react';
import { useNavigate } from 'react-router-dom';

// types
import {
  IFormatExtenderContentStep3,
  IGeneratedPageFormat,
} from '../../../types/studio-format-extender.types';

// styles
import '../../../styles/studio/format-extender.scss';

const GeneratedPageFormat = ({ pageFormat }: IGeneratedPageFormat) => {
  const navigate = useNavigate();

  const onEdit = () => {
    navigate({
      pathname: `/tool/scene/${pageFormat.digitalGood}/`,
      search: `?redirectedFromStudio=1`,
    });
  };

  const onProjectDownload = () => {
    const link = document.createElement('a');
    link.setAttribute('download', pageFormat.name);
    link.href = pageFormat.preview;

    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="generated-page-formats-container--page-format">
      <p className="generated-page-formats-container--page-format--title">
        {pageFormat.formatName}
      </p>
      <div className="generated-page-formats-container--page-format--image-container">
        <img
          src={pageFormat.preview}
          alt="project"
          className="generated-page-formats-container--page-format--image-container--image"
        />
      </div>
      <div className="generated-page-formats-container--page-format--actions-container">
        <button
          type="button"
          className="generated-page-formats-container--page-format--actions-container--button"
          onClick={onEdit}
        >
          <img src="/static/img/icons/edit-white.svg" alt="edit" />
          <span className="generated-page-formats-container--page-format--actions-container--button--title">
            Edit
          </span>
        </button>
        <button
          type="button"
          className="generated-page-formats-container--page-format--actions-container--button"
          onClick={onProjectDownload}
        >
          <img src="/static/img/icons/download-white.svg" alt="download" />
          <span className="generated-page-formats-container--page-format--actions-container--button--title">
            Download
          </span>
        </button>
      </div>
    </div>
  );
};

const FormatExtenderContentStep3 = ({
  generatedPageFormatsInfo,
  onGeneratedProjectsCancel,
  onGeneratedProjectsSave,
}: IFormatExtenderContentStep3) => {
  return (
    <>
      <div className="generated-page-formats-container">
        {generatedPageFormatsInfo.generatedProjects.map((pageFormat) => (
          <GeneratedPageFormat key={pageFormat.id} pageFormat={pageFormat} />
        ))}
      </div>
      <div className="generated-page-formats-container--btns-container">
        <button
          type="button"
          className="generated-page-formats-container--btns-container--btn cancel"
          onClick={onGeneratedProjectsCancel}
        >
          add more formats
        </button>
        <button
          type="button"
          className="generated-page-formats-container--btns-container--btn"
          onClick={onGeneratedProjectsSave}
        >
          Save ({generatedPageFormatsInfo.generatedProjects.length}) formats
        </button>
      </div>
    </>
  );
};

export default FormatExtenderContentStep3;

/* eslint-disable @typescript-eslint/no-empty-function */
// helpers
import { findCustomAssets } from '../../helpers/editor/customAssets';
import {
  customAssetsInfo,
  // customShapesGroups,
  customAssetSourceId,
  CUSTOM_DOCK_GROUP_ID,
} from '../../helpers/data/canvasConstants';

import {
  buildCategoryName,
  // retrieveImageAssetCategoryName,
} from '../../helpers/scene.helpers';

const CUSTOM_UPLOADS = 'custom-uploads';

const CUSTOM_GROUP_ENTRY_IDS = [
  // might be used later
  // customAssetSourceId.templates,
  customAssetSourceId.formats,
  customAssetSourceId.images,
  customAssetSourceId.pexels,
  customAssetSourceId.text,
  customAssetSourceId.shapes,
  // customAssetSourceId.stickers,
  // might be needed in future
  // customAssetSourceId.uploads,
  CUSTOM_UPLOADS,
  customAssetSourceId.layers,
  customAssetSourceId.ai_text,
  customAssetSourceId.replaceable,
  customAssetSourceId.systemImages,
];

export default ({
  fileName = '',
  onLoad = () => {},
  onSave = () => {},
  onDownload = () => {},
  onUpload = () => {},
  onImageDownload = () => {},
  onPagesPreviewDownload = () => {},
  onUploadedImageDelete = () => {},
  isAllExportOptions = false,
  imageAssetsCategories = [],
  // 'Photos sorted by categories' feature (uncomment if this feature should be applied)
  // photoAssetsCategories = [],
  customFonts = [],
  onExportPDF,
  isLayersVisible,
  isReplaceableFeatureEnabled,
  hasInternalAPIAccess,
}) => {
  const modifiedAssetCategories = imageAssetsCategories.map((category) => ({
    name: buildCategoryName(category.name, 'asset-category'),
    id: category.id,
  }));

  // const modifiedAssetsCategoriesNames = modifiedAssetCategories.map(
  //   (category) => category.name,
  // );
  // 'Photos sorted by categories' feature (uncomment if this feature should be applied)
  // const modifiedPhotoAssetCategories = photoAssetsCategories.map(
  //   (category) => ({
  //     name: buildCategoryName(category.name, 'photo-asset-category'),
  //     id: `photos-${category.id}`,
  //   }),
  // );

  // const modifiedPhotosCategoriesNames = modifiedPhotoAssetCategories.map(
  //   (category) => category.name,
  // );

  const buildCustomFontsObject = () => {
    // ?: function that converts snake_case to camelCase, needed for the weights element of each font
    // ?: https://img.ly/docs/cesdk/ui/configuration/presets-typefaces/#configuring-custom-typefaces

    // ?: matches every letter that has a preceding underscore (included), and replaces each occurrence with the uppercase letter
    // ?: test_string matches _s and replaces it with S

    // may be needed in future
    // const snakeToCamel = (string) =>
    //   string.replace(/(_\w)/g, (k) => k[1].toUpperCase());

    const customFontsObj = {};

    customFonts.forEach((customFont) => {
      const customFontKey = customFont.fontFamily
        .toLowerCase()
        .split(' ')
        .join('_');

      if (!(customFontKey in customFontsObj)) {
        customFontsObj[customFontKey] = {
          family: customFont.fontFamily,
          fonts: [],
        };
      }

      customFontsObj[customFontKey].fonts.push({
        fontURL: customFont.fontFile,
        weight: customFont.weight, // snakeToCamel(customFont.weight),
        style: customFont.style,
      });
    });

    return customFontsObj;
  };

  const buildCategoriesSourcesObject = (assetCategories) => {
    const assetsSourcesObj = {};

    assetCategories.forEach((category) => {
      assetsSourcesObj[category.name] = {
        id: category.name,
        findAssets: (query) =>
          findCustomAssets(query, {
            sourceId: category.name,
            blockType: '//ly.img.ubq/graphic',
            assetCategoryId: category.id,
          }),
      };
    });

    return assetsSourcesObj;
  };

  // const createLibrary = ({ id, sourceIds, titleFallback, iconUrl }) => {
  //   return {
  //     id,
  //     sourceIds,
  //     previewLength: 3,
  //     gridItemHeight: 'auto',
  //     gridBackgroundType: 'cover',
  //     gridColumns: 2,
  //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //     title: ({ _, sourceId }) => {
  //       if (sourceId) {
  //         return retrieveImageAssetCategoryName(sourceId);
  //       }
  //       return titleFallback;
  //     },
  //     icon: () => iconUrl,
  //   };
  // };

  // if needed to split images by categories - uncomment this
  // const imageLibrary = createLibrary({
  //   id: customAssetsInfo.images.sourceId,
  //   sourceIds: [...modifiedAssetsCategoriesNames],
  //   titleFallback: 'Images',
  //   iconUrl: 'https://img.ly/static/cesdk/guides/icon-large-dark.svg',
  // });

  // 'Photos sorted by categories' feature (uncomment if this feature should be applied)
  // const pexelsLibrary = createLibrary({
  //   id: customAssetsInfo.pexels.sourceId,
  //   sourceIds: [...modifiedPhotosCategoriesNames],
  //   titleFallback: 'Photos',
  //   iconUrl: 'https://img.ly/static/cesdk/guides/icon-large-dark.svg',
  // });

  const customActions = [
    {
      label: 'Save',
      iconName: 'save',
      callback: onSave,
    },
  ];

  if (isAllExportOptions) {
    customActions.push({
      label: 'Export Elements',
      iconName: 'download',
      callback: onImageDownload,
    });
  }

  customActions.push({
    label: 'Export PNG',
    iconName: 'default',
    callback: onPagesPreviewDownload,
  });

  customActions.push({
    label: 'Export JPG',
    iconName: 'default',
    callback: () => onPagesPreviewDownload(true),
  });

  return {
    license: process.env.CESDK_LICENSE_KEY || '',
    theme: 'dark',
    defaultFont: '//ly.img.cesdk.fonts/roboto_regular',
    role: 'Creator',
    presets: {
      typefaces: {
        ...buildCustomFontsObject(),
      },
    },
    ui: {
      pageFormats: {
        'twitter-profile': {
          default: true,
          width: 1080,
          height: 1080,
          unit: 'Pixel',
        },
      },
      elements: {
        view: 'advanced',
        navigation: {
          action: {
            ...(isAllExportOptions && { load: true }),
            export: {
              show: true,
              format: ['application/pdf'],
            },
            ...(isAllExportOptions && { download: true }),
            custom: customActions,
          },
          title: fileName,
        },
        dock: {
          groups: [
            {
              id: 'my-templates-group',
              entryIds: ['my-templates-entry'],
            },
            {
              id: CUSTOM_DOCK_GROUP_ID,
              entryIds: CUSTOM_GROUP_ENTRY_IDS,
            },
          ],
        },
        libraries: {
          insert: {
            floating: false,
            autoClose: false,
            entries: (defaultEntries) => {
              const defaultTextEntry = defaultEntries.filter(
                (entry) => customAssetsInfo.text.blockType === entry.id,
              );

              // might be needed in future
              // const defaultUploadsEntry = defaultEntries.filter(
              //   (entry) => customAssetsInfo.uploads.sourceId === entry.id,
              // );

              // might be used later
              // const defaultShapeEntry = defaultEntries.filter(
              //   (entry) => entry.id === 'ly.img.vectorpath',
              // );

              return [
                // might be used later
                // {
                //   id: customAssetsInfo.templates.sourceId,
                //   sourceIds: [customAssetsInfo.templates.sourceId],
                //   previewLength: 3,
                //   gridItemHeight: 'auto',
                //   gridBackgroundType: 'cover',
                //   gridColumns: 2,
                //   title: 'Templates',
                //   icon: () => '/static/img/icons/sidebar-canvas-templates.svg',
                // },
                {
                  id: 'my-templates-entry',
                  sourceIds: ['my-templates'],
                  previewLength: 3,
                  gridItemHeight: 'auto',
                  gridBackgroundType: 'cover',
                  gridColumns: 2,
                  title: 'Templates',
                  icon: '/static/img/icons/sidebar-canvas-templates.svg',
                },
                {
                  id: customAssetsInfo.formats.sourceId,
                  sourceIds: [customAssetsInfo.formats.sourceId],
                  title: 'Formats',
                  icon: '/static/img/icons/sidebar-canvas-formats.svg',
                },
                // imageLibrary,
                {
                  id: customAssetsInfo.images.sourceId,
                  sourceIds: [customAssetsInfo.images.sourceId],
                  title: 'Images',
                  icon: 'https://img.ly/static/cesdk/guides/icon-large-dark.svg',
                  previewLength: 3,
                  gridItemHeight: 'auto',
                  gridBackgroundType: 'cover',
                  gridColumns: 2,
                },
                // Photos all at once (comment this out if 'photos by categories' feature should be applied)
                {
                  id: customAssetsInfo.pexels.sourceId,
                  sourceIds: [customAssetsInfo.pexels.sourceId],
                  previewLength: 3,
                  gridItemHeight: 'auto',
                  gridBackgroundType: 'cover',
                  gridColumns: 2,
                  title: 'Photos',
                  icon: '/static/img/icons/sidebar-canvas-photos.svg',
                },
                // 'Photos sorted by categories' feature (uncomment if this feature should be applied)
                // pexelsLibrary,
                ...defaultTextEntry,
                {
                  id: customAssetsInfo.shapes.sourceId,
                  sourceIds: [
                    customAssetsInfo.shapes.sourceId,
                    // might be used later
                    // defaultShapeEntry[0].sourceIds[0],
                  ],
                  previewLength: 3,
                  gridBackgroundType: 'cover',
                  gridItemHeight: 'square',
                  gridColumns: 3,
                  title: 'Shapes',
                  // might be used later
                  // title: ({ group, sourceId }) => {
                  //   if (group) {
                  //     switch (group) {
                  //       case customShapesGroups.basic.id:
                  //         return customShapesGroups.basic.title;
                  //       case customShapesGroups.abstract.id:
                  //         return customShapesGroups.abstract.title;
                  //       default:
                  //         return 'Shapes';
                  //     }
                  //   } else {
                  //     switch (sourceId) {
                  //       case customAssetsInfo.shapes.sourceId:
                  //         return 'Shapes';
                  //       // case defaultShapeEntry[0].id:
                  //       //   return 'Basic';
                  //       default:
                  //         return 'Shapes';
                  //     }
                  //   }
                  // },
                  icon: '/static/img/icons/sidebar-canvas-shapes.svg',
                },
                // {
                //   id: customAssetsInfo.stickers.sourceId,
                //   sourceIds: [customAssetsInfo.stickers.sourceId],
                //   previewLength: 3,
                //   gridItemHeight: 'auto',
                //   gridBackgroundType: 'cover',
                //   gridColumns: 2,
                //   title: 'Stickers',
                //   icon: () => '/static/img/icons/sidebar-canvas-stickers.svg',
                // },
                // might be needed in future
                // ...defaultUploadsEntry,
                {
                  id: CUSTOM_UPLOADS,
                  sourceIds: [customAssetSourceId.customUploads],
                  title: 'Uploads',
                  icon: '/static/img/icons/sidebar-canvas-uploads.svg',
                  canAdd: true,
                  previewLength: 3,
                  gridItemHeight: 'auto',
                  gridBackgroundType: 'cover',
                  gridColumns: 2,
                  canRemove: true,
                },
                isLayersVisible && {
                  id: customAssetsInfo.layers.sourceId,
                  sourceIds: [customAssetsInfo.layers.sourceId],
                  title: 'Layers',
                  icon: '/static/img/icons/sidebar-canvas-layers.svg',
                },
                {
                  id: customAssetsInfo.ai_text.sourceId,
                  sourceIds: [customAssetsInfo.ai_text.sourceId],
                  title: 'A.I. Text',
                  icon: '/static/img/icons/ai-text-v2.svg',
                },
                isReplaceableFeatureEnabled && {
                  id: customAssetsInfo.replaceable.sourceId,
                  sourceIds: [customAssetsInfo.replaceable.sourceId],
                  title: 'Replaceable',
                  icon: '/static/img/icons/ai-text.svg',
                },
                hasInternalAPIAccess && {
                  id: customAssetsInfo.systemImages.sourceId,
                  sourceIds: [customAssetsInfo.systemImages.sourceId],
                  title: 'System Images',
                  icon: 'https://img.ly/static/cesdk/guides/icon-large-dark.svg',
                },
              ];
            },
          },
          replace: {
            floating: false,
            entries: () => {
              return [
                // imageLibrary,
                {
                  id: customAssetsInfo.images.sourceId,
                  sourceIds: [customAssetsInfo.images.sourceId],
                  title: 'Images',
                  icon: 'https://img.ly/static/cesdk/guides/icon-large-dark.svg',
                  previewLength: 3,
                  gridItemHeight: 'auto',
                  gridBackgroundType: 'cover',
                  gridColumns: 2,
                },
                {
                  id: CUSTOM_UPLOADS,
                  sourceIds: [customAssetSourceId.customUploads],
                  title: 'Uploads',
                  canAdd: true,
                  previewLength: 3,
                  gridItemHeight: 'auto',
                  gridBackgroundType: 'cover',
                  gridColumns: 2,
                },
                // Photos all at once (comment this out if 'photos by categories' feature should be applied)
                {
                  id: customAssetsInfo.pexels.sourceId,
                  sourceIds: [customAssetsInfo.pexels.sourceId],
                  previewLength: 3,
                  gridItemHeight: 'auto',
                  gridBackgroundType: 'cover',
                  gridColumns: 2,
                },
                // 'Photos sorted by categories' feature (uncomment if this feature should be applied)
                // pexelsLibrary,
                hasInternalAPIAccess && {
                  id: customAssetsInfo.systemImages.sourceId,
                  sourceIds: [customAssetsInfo.systemImages.sourceId],
                  title: 'System Images',
                  icon: 'https://img.ly/static/cesdk/guides/icon-large-dark.svg',
                },
              ];
            },
          },
        },
      },
    },
    assetSources: {
      customAssetSource: {
        addAsset: (asset) => asset,
        findAssets: (query) =>
          findCustomAssets(query, customAssetsInfo.customUploads),
        getSupportedMimeTypes() {
          return ['image/jpeg', 'image/png'];
        },
        removeAsset: (id) => onUploadedImageDelete(id),
      },
      images: {
        id: customAssetsInfo.images.sourceId,
        findAssets: (query) => findCustomAssets(query, customAssetsInfo.images),
      },
      // stickers: {
      //   id: customAssetsInfo.stickers.sourceId,
      //   findAssets: (query) =>
      //     findCustomAssets(query, customAssetsInfo.stickers),
      // },
      shapes: {
        id: customAssetsInfo.shapes.sourceId,
        findAssets: (query) => findCustomAssets(query, customAssetsInfo.shapes),
      },
      // might be used later
      // templates: {
      //   id: customAssetsInfo.templates.sourceId,
      //   findAssets: (query) =>
      //     findCustomAssets(query, customAssetsInfo.templates),
      // },
      ...(isLayersVisible && {
        layers: {
          id: customAssetsInfo.layers.sourceId,
          findAssets: () => {},
        },
      }),
      formats: {
        id: customAssetsInfo.formats.sourceId,
        findAssets: () => {},
      },
      // Photos all at once (comment this out if 'photos by categories' feature should be applied)
      pexels: {
        id: customAssetsInfo.pexels.sourceId,
        findAssets: (query) => findCustomAssets(query, customAssetsInfo.pexels),
      },
      ...buildCategoriesSourcesObject(modifiedAssetCategories),
      // 'Photos sorted by categories' feature (uncomment if this feature should be applied)
      // ...buildCategoriesSourcesObject(modifiedPhotoAssetCategories),
      ai_text: {
        id: customAssetsInfo.ai_text.sourceId,
        findAssets: () => {},
      },
      replaceable: {
        id: customAssetsInfo.replaceable.sourceId,
        findAssets: () => {},
      },
      ...(hasInternalAPIAccess && {
        systemImages: {
          id: customAssetsInfo.systemImages.sourceId,
          findAssets: (query) =>
            findCustomAssets(query, customAssetsInfo.systemImages),
        },
      }),
    },
    callbacks: {
      onLoad,
      onExport: onExportPDF,
      onDownload,
      onUpload,
    },
  };
};

import React from 'react';

// types
import {
  IPageFormatsSection,
  IPageFormatOption,
} from '../../../types/studio-format-extender.types';

// styles
import '../../../styles/studio/format-extender.scss';

const PageFormatOption = ({
  pageFormat,
  onPageFormatSelect,
}: IPageFormatOption) => {
  return (
    <div className="formats-container--dropdown--formats--option">
      <img
        src={pageFormat.originalLogo}
        alt="format icon"
        className="formats-container--dropdown--formats--option--icon"
      />{' '}
      <p className="formats-container--dropdown--formats--option--name">
        {pageFormat.name}
      </p>{' '}
      <input
        type="checkbox"
        className="formats-container--dropdown--formats--option--checkbox"
        onChange={(event) => onPageFormatSelect(pageFormat, event)}
      />
    </div>
  );
};

const PageFormatsSection = ({
  pageFormats,
  onPageFormatSelect,
}: IPageFormatsSection) => {
  return (
    <div className="formats-container--dropdown">
      <div className="formats-container--dropdown--input-container">
        <input
          type="text"
          className="formats-container--dropdown--input-container--input"
          placeholder="Search"
        />
        <img
          src="/static/img/icons/search.svg"
          alt="search"
          className="formats-container--dropdown--input-container--icon"
        />
      </div>
      <div className="formats-container--dropdown--formats">
        {pageFormats.map((pageFormat) => (
          <PageFormatOption
            key={pageFormat.id}
            pageFormat={pageFormat}
            onPageFormatSelect={onPageFormatSelect}
          />
        ))}
      </div>
    </div>
  );
};

export default PageFormatsSection;

import React from 'react';
import cn from 'classnames';

// types
import { IFolderContextMenu } from '../../../types/studio.types';

const FolderContextMenu = ({
  isVisible,
  position,
  openFolderRenameModal,
  onProjectFolderDuplicate,
  openFolderDeleteModal,
  isFolderDuplicateLoading,
}: IFolderContextMenu) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ top: position.y, left: position.x }} className="context-menu">
      <button
        className="context-menu--option"
        id="folder-context-menu-rename"
        onClick={openFolderRenameModal}
        type="button"
      >
        <img src="/static/img/icons/rename.svg" alt="rename" />
        <span>Rename</span>
      </button>

      <button
        className={cn('context-menu--option', {
          disabled: isFolderDuplicateLoading,
        })}
        id="folder-context-menu-duplicate"
        onClick={onProjectFolderDuplicate}
        type="button"
      >
        <img src="/static/img/icons/copy-white.svg" alt="duplicate" />
        <span>{isFolderDuplicateLoading ? 'Loading ...' : 'Duplicate'}</span>
      </button>

      <button
        className="context-menu--option"
        id="folder-context-menu-delete"
        onClick={openFolderDeleteModal}
        type="button"
      >
        <img src="/static/img/icons/delete-white.svg" alt="delete" />
        <span>Delete</span>
      </button>
    </div>
  );
};

export default FolderContextMenu;

import { randomizeTemplate } from '../../api/digitalGood';
import { reRollImages } from '../../api/helpers';

export const injectHeaderLogo = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const headerEl = $("[class^='UBQ_Topbar-module__block']", shadowRootEl);
  const headerLeftEl = $(
    "[class^='UBQ_Topbar-module__controlsContainerLeft']",
    headerEl,
  );

  const styles = {
    logo: `
            background: url('/static/img/full-white-logo.svg');
            width: 102px;
            height: 25px;
        `,
  };

  const template = `
      <div style="${styles.logo}"></div>
    `;

  headerLeftEl.prepend(template);
};

export const injectProjectBtn = async (
  label = 'Projects',
  callback = () => console.warn('Set project btn callback!'),
) => {
  await new Promise((resolve) => {
    setTimeout(resolve, 1000);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const headerEl = $("[class^='UBQ_Topbar-module__block']", shadowRootEl);
  const headerLeftEl = $(
    "[class^='UBQ_Topbar-module__controlsContainerLeft']",
    headerEl,
  );
  const projectBtnId = 'projectBtn';

  const styles = {
    arrow: `
            transform: rotate(180deg);
            width: 16px;
            height: 16px;
            fill: none
        `,
    path: `
            stroke: currentcolor;
            stroke-opacity: 0.9;
            stroke-width: 1.5;
            stroke-linecap: square;
            stroke-linejoin: round;
        `,
  };

  const template = $(`
        <button
            type="button"
            class="${$(
              '[class^="UBQ_Button-module__block"]',
              shadowRootEl,
            ).attr('class')}"
            id=${projectBtnId}"
        >
            <span>
                <svg
                    style="${styles.arrow}"
                    class="UBQ_AssetLibrarySection-module__moreIcon--NstTc"
                >
                    <path style="${styles.path}">
                      <animate attributeName="d" values="M 6.5 11.5 L 10 8 L 6.5 4.5" />
                    </path>
                </svg>
                <span>${label}</span>
            </span>
        </button>
    `).on('click', callback);

  if (!$(`#${projectBtnId}`, headerEl).length) {
    headerLeftEl.prepend(template);
  }
};

export const injectEditableTitle = async (
  callback = () => console.warn('Set title callback!'),
) => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const headerEl = $("[class^='UBQ_Topbar-module__block']", shadowRootEl);
  const titleEl = $("[class^='UBQ_Heading-module__heading']", headerEl);

  const styles = {
    templateContainer: `
            display: inline-flex;
            align-items: center;
            overflow: hidden;
            padding: 2px 4px;
            min-height: auto;
        `,
    templateTextContainer: `
            flex: 1;
            overflow: hidden;
        `,
    templateHeading: `
            min-width: auto;
            outline: none;
            max-width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        `,
    templateIcon: `
            margin-left: 7px;
            margin-top: 4px;
            opacity: 0;
        `,
  };

  const template = `
        <div
            style="${styles.templateContainer}"
            id="editable-title-container"
        >
            <div style="${styles.templateTextContainer}">
            <h2
                class="${titleEl.attr('class')}"
                style="${styles.templateHeading}"
                contenteditable="true"
                id="editableTitle"
                data-prevval="${titleEl.text()}"
            >
                ${titleEl.text()}
            </h2>
            </div>
            <img
                style="${styles.templateIcon}"
                src="/static/img/icons/edit-white.svg"
                alt="edit"
                width="10px"
                height="10px"
                id="editable-title-icon"
            />
        </div>
    `;

  titleEl.replaceWith(template);

  let titleText = $('#editableTitle', headerEl)[0]?.innerText;

  // might be used later
  // const onStartEditingTitle = (elToEdit) => {
  //   const win = window;
  //   const doc = win.document;
  //   let selection;
  //   let range;

  //   if (win.getSelection && doc.createRange) {
  //     selection = win.getSelection();
  //     range = doc.createRange();
  //     range.selectNodeContents(elToEdit);
  //     selection.removeAllRanges();
  //     selection.addRange(range);
  //   } else if (doc.body.createTextRange) {
  //     range = doc.body.createTextRange();
  //     range.moveToElementText(elToEdit);
  //     range.select();
  //   }
  // };

  const hideEditableTitleOutline = () => {
    $('#editable-title-icon', headerEl).attr(
      'style',
      'opacity: 0; margin-left: 7px; margin-top: 4px;',
    );
    $('#editable-title-container', headerEl).attr(
      'style',
      'background: transparent;display: inline-flex;align-items: center;overflow: hidden;padding: 4px;min-height: auto;',
    );
  };

  $('#editable-title-container', headerEl).on('mouseenter', () => {
    if ($('#editableTitle', headerEl).is(':focus')) {
      return;
    }

    $('#editable-title-icon', headerEl).attr(
      'style',
      'opacity: 1; margin-left: 7px; margin-top: 4px;',
    );
    $('#editable-title-container', headerEl).attr(
      'style',
      'border-radius: 4px;background: #727272;display: inline-flex;align-items: center;overflow: hidden;padding: 4px;min-height: auto;',
    );
  });

  $('#editable-title-container', headerEl).on(
    'mouseleave',
    hideEditableTitleOutline,
  );

  // might be used later
  // $('#editable-title-icon', headerEl).click(() =>
  //   onStartEditingTitle($('#editableTitle', headerEl)[0]),
  // );

  // $('#editableTitle', headerEl).click(({ target }) =>
  //   onStartEditingTitle(target),
  // );

  $('#editableTitle', headerEl).on('keyup', (event) => {
    if (event.key === 'Escape') {
      $('#editableTitle', headerEl).text(titleText);
      $(event.target).blur();
    }
  });

  $('#editableTitle', headerEl).on('keydown', (event) => {
    if (event.which === 13) {
      event.preventDefault();
      $(event.target).blur();
    }
  });

  $('#editableTitle', headerEl).focus(hideEditableTitleOutline);

  $('#editableTitle', headerEl).blur(({ target }) => {
    const el = $(target);
    const value = el.text();

    if (!value.length) {
      console.warn("title can't be blank");

      el.text(el.attr('data-prevval'));

      return;
    }

    el.css('overflow', 'hidden');
    el.scrollLeft(0);
    el.attr('data-prevval', value);

    titleText = $('#editableTitle', headerEl)[0].innerText;

    callback(value);
  });
};

export const injectShareLinkButton = async (callback = () => {}) => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const headerEl = $("[class^='UBQ_Topbar-module__block']", shadowRootEl);
  const headerRightEl = $(
    "[class^='UBQ_Topbar-module__controlsContainerRight']",
    headerEl,
  );
  const actionsDropdownEl = $(headerRightEl[0]?.lastElementChild);

  const styles = {
    shareIcon: `
      width: 14px;
      height: 14px;
      fill: #fff;
    `,
  };
  const shareLinkButtonId = 'shareLinkButtonId';

  const shareLinkButton = $(`
    <button
        class="${$('[class^="UBQ_Button-module__block"]', shadowRootEl).attr(
          'class',
        )}"
        id="${shareLinkButtonId}"
    >
      <span>
        <svg viewBox="0 0 17 17" style="${styles.shareIcon}">
          <path
            d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499
            0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0
            0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
          />
        </svg>
        <span>Share</span>
      </span>
    </button>
  `).on('click', callback);

  if (!$(`#${shareLinkButtonId}`, headerEl).length) {
    actionsDropdownEl.before($(shareLinkButton));
  }
};

export const injectRandomizeTemplateButton = async (cesdk, templateGenre) => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const headerEl = $("[class^='UBQ_Topbar-module__block']", shadowRootEl);
  const headerLeftEl = $(
    "[class^='UBQ_Topbar-module__controlsContainerLeft']",
    headerEl,
  );

  const handleRandomizeTemplateButtonClick = async () => {
    if (!templateGenre) {
      alert(
        'Template genre could not be found, Randomize Template button is disabled.',
      );
      return;
    }

    const savedScene = await cesdk.save();

    const scenePageFormatFD = new FormData();
    scenePageFormatFD.append('scene_bytes', savedScene);
    scenePageFormatFD.append('genre', templateGenre);

    randomizeTemplate(scenePageFormatFD).then((resp) => {
      cesdk.loadFromString(resp.scaled_scene);
    });
  };

  const randomizeTemplateButton = $(`
        <button
            type="button"
            class="${$(
              '[class^="UBQ_Button-module__block"]',
              shadowRootEl,
            ).attr('class')}"
            id=randomize-template"
        >
            <span>
                <span>Randomize Template</span>
            </span>
        </button>
    `).on('click', handleRandomizeTemplateButtonClick);

  const observer = new MutationObserver(() => {
    if ($("[class^='UBQ_UndoControls-module__block--lVvtW']", headerLeftEl)) {
      headerLeftEl.append(randomizeTemplateButton);
    }
  });

  observer.observe(shadowRootEl, {
    attributes: true,
    subtree: true,
  });
};

export const hidePreviewButton = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const headerEl = $("[class^='UBQ_Topbar-module__block']", shadowRootEl);
  const headerRightEl = $(
    "[class^='UBQ_Topbar-module__controlsContainerRight']",
    headerEl,
  );
  const previewButton = $("[data-cy^='previewButton']", headerRightEl);

  previewButton.hide();
};

export const injectReRollImagesButton = async (id, cesdk) => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });

  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const headerEl = $("[class^='UBQ_Topbar-module__block']", shadowRootEl);
  const headerLeftEl = $(
    "[class^='UBQ_Topbar-module__controlsContainerLeft']",
    headerEl,
  );

  const onReRollImages = () => {
    reRollImages(id).then((resp) => {
      cesdk.loadFromURL(resp.resource);
    });
  };

  const reRollImagesButton = $(`
        <button
            type="button"
            class="${$(
              '[class^="UBQ_Button-module__block"]',
              shadowRootEl,
            ).attr('class')}"
            id="re-roll-images"
        >
            <span>
                <span>Re-roll Images</span>
            </span>
        </button>
    `).on('click', onReRollImages);

  const observer = new MutationObserver(() => {
    if ($("[class^='UBQ_UndoControls-module__block']", headerLeftEl)) {
      headerLeftEl.append(reRollImagesButton);
    }
  });

  observer.observe(shadowRootEl, {
    attributes: true,
    subtree: true,
  });
};

export const adjustSceneAppColours = () => {
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;

  const observer = new MutationObserver(() => {
    $("[class^='UBQ_Topbar-module__block']", shadowRootEl).css(
      'background',
      '#3F3F3F',
    );

    $("[class^='UBQ_Canvas-module__block']", shadowRootEl).css(
      'background',
      '#212121',
    );

    $("[class^='UBQ_Dock-module__block']", shadowRootEl).css(
      'background',
      '#484848',
    );

    $('#ubq-portal-container_panelRight', shadowRootEl)
      .children()
      .css('background', '#545454');

    if ($("[data-cy^='asset-library-panel']", shadowRootEl)) {
      $("[data-cy^='asset-library-panel']", shadowRootEl).css(
        'background',
        '#545454',
      );
    }
  });

  observer.observe(shadowRootEl, {
    attributes: true,
    subtree: true,
  });
};

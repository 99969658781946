import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// import Header from '../Header';
// import ProjectsButton from '../Header/ProjectsButton';
// import UndoButton from '../Header/UndoButton';
// import EditableTitle from '../Header/EditableTitle';
// import ZoomButton from '../Header/ZoomButton';
// import ActionButton from '../Header/ActionButton';
import StudioHeader from './StudioHeader';
import StudioSidebar from './StudioSidebar';
import StudioContent from './StudioContent';
// import Sidebar from '../Sidebar';
// import TopBar from '../Topbar';
// import Scene from '../Scene';
import UnsupportedDeviceScreen from '../UnsupportedDeviceScreen';

import { actions as sidebarContentActions } from '../../ducks/sidebarContent';
import { actions as topbarActions } from '../../ducks/topbar';
import { useAppDispatch, useAppSelector, useMount } from '../../helpers/hooks';
import { actions as profileActions } from '../../ducks/profile';

// import { InputChangeEventType } from '../../types/common.types';

import { getProfile } from '../../api/profile';

import { getNavbarData, sidebarContentData, topbarData } from './config';
import { userPermissions } from '../../helpers/constants';

import '../../styles/main-container.scss';
import { StudioSidebarType } from '../../types/studio.types';

const StudioEditor = () => {
  const dispatch = useAppDispatch();

  // const [projectTitle, setProjectTitle] = useState('Test project name');
  // const [zoomValue, setZoomValue] = useState('100%');
  // const [isZoomDropdownOpened, setIsZoomDropdownOpened] = useState(false);
  const [selectedSidebarElementId, setSelectedSidebarElementId] = useState(0);
  // might be a part of another state. depending on back-end
  const [userCreditsAmount, setUserCreditsAmount] = useState(0);
  const [sidebarData, setSidebarData] =
    useState<Array<StudioSidebarType> | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const userProfile = useAppSelector((state) => state.userProfile);

  const initialSidebarElementId = searchParams.get('initialSidebarElementId');

  useMount(() => {
    dispatch(sidebarContentActions.setSidebarContent(sidebarContentData));
    dispatch(topbarActions.setTopbar(topbarData));
  });

  useEffect(() => {
    const studioPermissions = {
      hasStudioRegen: userProfile.permissions.includes(
        userPermissions.hasStudioRegen,
      ),
      hasStudioFormatExtender: userProfile.permissions.includes(
        userPermissions.hasStudioFormatExtender,
      ),
      hasStudioBrandRoom: userProfile.permissions.includes(
        userPermissions.hasStudioBrandRoom,
      ),
      hasStudioGenerativeBrief: userProfile.permissions.includes(
        userPermissions.hasStudioGenerativeBrief,
      ),
      hasStudioLayers: userProfile.permissions.includes(
        userPermissions.hasStudioLayers,
      ),
      hasStudioPromptToDesign: userProfile.permissions.includes(
        userPermissions.hasStudioPromptToDesign,
      ),
    };

    const studioSidebarData = getNavbarData(studioPermissions);
    setSidebarData(studioSidebarData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile.permissions]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const response = await getProfile();

      dispatch(profileActions.setProfile(response));
    };

    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      initialSidebarElementId &&
      typeof +initialSidebarElementId === 'number'
    ) {
      setSelectedSidebarElementId(+initialSidebarElementId);
    }

    setTimeout(() => {
      searchParams.delete('initialSidebarElementId');
      setSearchParams(searchParams);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSidebarElementId]);

  // const [searchParams] = useSearchParams();

  // const templateId = searchParams.get('templateId');

  // const onProjectsButtonClick = () => {
  //   window.location.href = templateId ? '/templates/' : '/projects/';
  // };

  // const onTitleChange = (e: InputChangeEventType) => {
  //   setProjectTitle(e.target.value);
  // };

  // const openZoomDropdown = () => {
  //   setIsZoomDropdownOpened(true);
  // };

  // const closeZoomDropdown = () => {
  //   setIsZoomDropdownOpened(false);
  // };

  // const onZoomOptionSelect = (zoomOptionValue: string) => {
  //   zoomOptionValue && setZoomValue(zoomOptionValue);
  // };

  const handleSelectSidebarElement = (id: number) => {
    setSelectedSidebarElementId(id);
  };

  if (window && window.innerWidth < 768) {
    return <UnsupportedDeviceScreen />;
  }

  return (
    <div>
      <div className="Editor__block">
        {/* <Header
          leftEl={
            <>
              <ProjectsButton
                handleClick={onProjectsButtonClick}
                label={templateId ? 'Templates' : 'Projects'}
              />
              <UndoButton isRedo />
            </>
          }
          middleEl={
            <EditableTitle title={projectTitle} handleChange={onTitleChange} />
          }
          rightEl={
            <>
              <ZoomButton
                isDropdownOpened={isZoomDropdownOpened}
                zoomValue={zoomValue}
                openZoomDropdown={openZoomDropdown}
                closeZoomDropdown={closeZoomDropdown}
                onZoomOptionSelect={onZoomOptionSelect}
              />
              <ActionButton />
            </>
          }
        /> */}
        <StudioHeader userCreditsAmount={userCreditsAmount} />
        <div className="Editor__body">
          {/* <TopBar selectedMenuId={selectedId} />
          <Sidebar
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            groupParams={{ elementsInRow: 3, backgroundCover: false }}
            gridParams={{ elementsInRow: 2, backgroundCover: true }}
          /> */}
          <StudioSidebar
            selectedSidebarElementId={selectedSidebarElementId}
            handleSelectSidebarElement={handleSelectSidebarElement}
            sidebarData={sidebarData}
          />
          <div className="Editor__canvasContainer">
            <StudioContent
              selectedSidebarElementId={selectedSidebarElementId}
            />
            {/* <Scene /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudioEditor;

export const ASSETS_PAGE_SIZE = 15;

export const CUSTOM_DOCK_GROUP_ID = 'CUSTOM_DOCK_GROUP';

export const customAssetSourceId = {
  images: 'custom-images',
  stickers: 'custom-stickers',
  shapes: 'custom-shapes',
  templates: 'custom-templates',
  text: 'ly.img.text',
  uploads: 'custom-uploads',
  layers: 'layers',
  formats: 'formats',
  pexels: 'pexels-images',
  ai_text: 'ai-text',
  customUploads: 'customAssetSource',
  replaceable: 'replaceable',
  systemImages: 'systemImages',
};

export const customAssetsInfo = {
  images: {
    blockType: '//ly.img.ubq/graphic',
    sourceId: customAssetSourceId.images,
  },
  stickers: {
    blockType: '//ly.img.ubq/graphic',
    sourceId: customAssetSourceId.stickers,
  },
  shapes: {
    blockType: '//ly.img.ubq/graphic',
    sourceId: customAssetSourceId.shapes,
  },
  templates: {
    blockType: 'ly.img.template',
    sourceId: customAssetSourceId.templates,
  },
  text: {
    blockType: 'ly.img.text',
    sourceId: customAssetSourceId.text,
  },
  uploads: {
    blockType: '//ly.img.ubq/graphic',
    sourceId: customAssetSourceId.uploads,
  },
  layers: {
    blockType: 'layers',
    sourceId: customAssetSourceId.layers,
  },
  formats: {
    blockType: 'formats',
    sourceId: customAssetSourceId.formats,
  },
  pexels: {
    blockType: '//ly.img.ubq/graphic',
    sourceId: customAssetSourceId.pexels,
  },
  ai_text: {
    blockType: 'ai-text',
    sourceId: customAssetSourceId.ai_text,
  },
  customUploads: {
    blockType: '//ly.img.ubq/graphic',
    sourceId: customAssetSourceId.customUploads,
  },
  replaceable: {
    blockType: 'replaceable',
    sourceId: customAssetSourceId.replaceable,
  },
  systemImages: {
    blockType: '//ly.img.ubq/graphic',
    sourceId: customAssetSourceId.systemImages,
  },
};

export const customShapesGroups = {
  basic: {
    id: '//ly.img.cesdk.vectorpaths/category/vectorpaths',
    title: 'Basic',
  },
  abstract: {
    id: '//ly.img.cesdk.vectorpaths.abstract/category/abstract',
    title: 'Abstract',
  },
};

export const cesdkBlockTypes = {
  image: 'image',
  text: 'text',
  sticker: 'sticker',
  shape: 'shape',
};

export const assetCategoryLibraryType = {
  images: 'images',
  stickers: 'stickers',
  shapes: 'shapes',
  textures: 'textures',
  threeDModels: '3d-models',
};

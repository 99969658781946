/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import CreativeEditorSDK from '@cesdk/cesdk-js';
import { encode, decode } from 'js-base64';
import useStateRef from 'react-usestateref';
import mixpanel from 'mixpanel-browser';
import BackgroundRemovalPlugin from '@imgly/plugin-background-removal-web';

// components
import BottomActions from '../BottomActions';
import UnsupportedDeviceScreen from '../UnsupportedDeviceScreen';

// redux
import { useAppSelector, useAppDispatch } from '../../helpers/hooks';
import { actions as profileActions } from '../../ducks/profile';

// api
import {
  createUploadedImage,
  deleteUploadedImageById,
} from '../../api/userUploadedImages';
import { createProject } from '../../api/projects'; // createShareProjectHash
import {
  getDigitalGoodById,
  partialUpdateDigitalGoodById,
  createDigitalGood,
  getDigitalGoodTemplateById,
} from '../../api/digitalGood';
import {
  // removeImageBackground,
  getPageFormats,
  getAssetsCategoriesByLibrary,
  getCustomFonts,
  // getCustomTemplates,
  getDefaultFonts,
} from '../../api/assets';
import {
  getDesignerHelpCategories,
  requestDesignerHelp,
  getFeedbackCategories,
  createFeedback,
} from '../../api/modals';
import { getProfile } from '../../api/profile';
import { editPromptToDesignData } from '../../api/helpers';

// helpers
import getConfig from './config';
// import jqueryModal from '../../helpers/jqueryModal';
import {
  generateCustomThemeStyle,
  generateCustomTheme,
} from '../../helpers/editor/theme';
import {
  injectProjectBtn,
  injectEditableTitle,
  // injectShareLinkButton,
  injectRandomizeTemplateButton,
  hidePreviewButton,
  injectReRollImagesButton,
  adjustSceneAppColours,
} from '../../helpers/editor/customHeaderElements';
import {
  modifyFontDropdown,
  fixRightSidebarPosition,
  hideInspectorBarPagesSectionElements,
  modifyErrorModal,
  removePlaceholder,
  removeInsertVariablesSection,
  hideExpandDefaultColorsButton,
} from '../../helpers/editor/customInspectorBar';
import base64ToFile from '../../helpers/base64ToFile';
import { findCustomAssets } from '../../helpers/editor/customAssets';
import {
  customAssetsInfo,
  customAssetSourceId,
  assetCategoryLibraryType,
  cesdkLibraryIcons,
} from '../../helpers/data/canvasConstants';
import initSidebarCustomLogic, {
  // autoOpenTemplatesDock,
  applyHorizontalGapForTemplatesElement,
  adjustSceneBottomSection,
} from '../../helpers/editor/customSidebar';
import generateZip from '../../helpers/editor/generateZip';
import { userPermissions } from '../../helpers/constants';

const STUDIO_TOOL_PERMISSION = 'STUDIO_TOOL';

mixpanel.init(process.env.MIXPANEL_TOKEN, {
  debug: false,
  track_pageview: true,
  persistence: 'localStorage',
});

const getFixedScene = (scene) =>
  encode(
    decode(scene)
      .replace(/(https?:\/\/[^\s]+(?="))/g, (url) => {
        const { origin, pathname } = new URL(url);

        return origin + pathname;
      })
      .replace(/NaN/g, 0),
  );

const generateImageByBlockId = (cesdk, id, isJpg) =>
  new Promise((resolve, reject) => {
    cesdk.engine.block
      .export(id)
      .then((blob) => {
        // Create an Image element to load the Blob
        const image = new Image();
        image.src = URL.createObjectURL(blob);

        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const maxWidth = 600;
          const maxHeight = 600;

          let newWidth = image.width;
          let newHeight = image.height;
          if (newWidth > maxWidth) {
            newHeight *= maxWidth / newWidth;
            newWidth = maxWidth;
          }
          if (newHeight > maxHeight) {
            newWidth *= maxHeight / newHeight;
            newHeight = maxHeight;
          }

          // Set the canvas dimensions to the new size
          canvas.width = newWidth;
          canvas.height = newHeight;

          // Draw the image on the canvas with the new dimensions
          ctx.drawImage(image, 0, 0, newWidth, newHeight);

          // Convert the canvas content to base64
          const resizedBase64 = isJpg
            ? canvas.toDataURL('image/jpeg')
            : canvas.toDataURL('image/png');

          resolve(
            base64ToFile(
              resizedBase64,
              `${Date.now()}.${isJpg ? 'jpg' : 'png'}`,
            ),
          );
        };
      })
      .catch(() => {
        reject(alert('Cannot save blank project. Please select a template.'));
      });
  });

function CanvasEditor() {
  const cesdkRef = useRef(null);
  const uploadFileRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataObj, setDataObj] = useState();
  const [savedScene, setSavedScene] = useState('');
  const editableData = useRef({});
  const [isSdkInitialized, setIsSdkInitialized] = useState(false);
  // move it to redux in case page formats will be used somewhere else besides scene
  const [scenePageFormats, setScenePageFormats] = useState([]);
  const [imageAssetsCategories, setImageAssetsCategories] = useState([]);
  const [customFonts, setCustomFonts] = useState([]);
  const [recommendedFonts, setRecommendedFonts] = useState([]);
  const [internationalFonts, setInternationalFonts] = useState([]);
  const [changedBlocks, setChangedBlocks] = useState({});
  const [isEditedMoreThanThreeThings, setIsEditedMoreThanThreeThings] =
    useState(false);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

  const [isSceneChanged, setIsSceneChanged] = useStateRef(false); // when onShareProject fn is uncommented - add isSceneChangedRef as third element in array
  const { redirectedFromStudio, p2dId, generatedByP2D, origin_type } = useMemo(
    () => Object.fromEntries([...searchParams]),
    [searchParams],
  );
  // template or project
  const { id: sceneId } = useParams();
  const isProjectsOrigin = origin_type === 'projects';
  const isTemplatesOrigin = origin_type === 'templates';
  const isP2DOrigin = origin_type === 'p2d';

  const timeOfCesdkInitialization = new Date();

  const userProfile = useAppSelector((state) => state.userProfile);

  const dispatch = useAppDispatch();

  const updateEditableData = useCallback((params = {}) => {
    editableData.current = {
      ...editableData.current,
      ...params,
    };
  }, []);

  let originType = 'blank';

  if (isTemplatesOrigin) {
    originType = 'templates';
  } else if (isProjectsOrigin) {
    originType = 'projects';
  } else if (p2dId) {
    originType = 'p2d';
  }

  const convertTimeSpent = (ms) => {
    let seconds = ms / 1000;

    const hours = parseInt(seconds / 3600, 10);
    seconds %= 3600;

    const minutes = parseInt(seconds / 60, 10);

    seconds %= 60;

    return `${hours}:${minutes}:${Math.floor(seconds)}:${ms % 1000}`;
  };

  const onImportFile = useCallback((e) => {
    const { files } = e.target;
    if (files.length === 0) return;

    const reader = new FileReader();
    reader.onload = ({ target: { result } }) =>
      cesdkRef.current.loadFromString(result);
    reader.readAsText(files[0]);
  }, []);

  const getPreviewFile = useCallback(async () => {
    if (!cesdkRef?.current?.engine) return null;

    const cesdk = cesdkRef.current;
    const currentSceneId = cesdk.engine.scene.get();
    const file = await generateImageByBlockId(cesdk, currentSceneId);

    return file;
  }, [cesdkRef]);

  const onSave = useCallback(
    async () => {
      const queryParams = new URLSearchParams(searchParams);
      const cesdk = cesdkRef.current;
      const scene = await cesdk.save();

      const dgFormData = new FormData();
      const fileName = `Scene_${Date.now()}`;
      const resourceFile = new File(
        [getFixedScene(scene)], // temporary fix for COOL-187
        `${fileName}.scene`,
        {
          type: 'file/scene',
        },
      );
      const previewFile = await getPreviewFile();

      dgFormData.append('name', editableData.current.name);
      dgFormData.append('resource', resourceFile);
      dgFormData.append('preview', previewFile);
      dgFormData.append('format', queryParams.get('formatId'));

      const replaceableString = queryParams.get('replaceable');
      const replaceableList = JSON.parse(replaceableString);

      replaceableList &&
        replaceableList.forEach((item, index) => {
          dgFormData.append(`replaceable_layers[${index}]`, item);
        });

      if (
        editableData.current?.templateId ||
        (isTemplatesOrigin && sceneId) ||
        dataObj.template
      ) {
        dgFormData.append(
          'template',
          editableData.current?.templateId ||
            (isTemplatesOrigin && sceneId) ||
            dataObj.template,
        );
      }

      // TODO check if isSceneChanged, if not then return
      setSavedScene(scene);

      if (localStorage.getItem('sceneSaved') === 'null') {
        mixpanel.identify(userProfile.id);

        mixpanel.track('Save Design', {
          design_token: new Date().valueOf(),
          origin_type: originType,
          time_spent: convertTimeSpent(
            new Date().getTime() - timeOfCesdkInitialization.getTime(),
          ),
          number_of_edits: localStorage.getItem('editsAmount'),
        });
      }

      // if file exists then update
      if (isProjectsOrigin) {
        partialUpdateDigitalGoodById(sceneId, dgFormData);
      } else {
        try {
          const createDigitalGoodResponse = await createDigitalGood(dgFormData);
          const dateNow = Date.now(); // temporary variable
          const prFormData = new FormData();

          prFormData.append('name', `Project ${dateNow}`);
          prFormData.append('description', `Description ${dateNow}`);
          prFormData.append('digital_good', createDigitalGoodResponse.id);
          await createProject(prFormData);
        } catch (error) {
          modifyErrorModal(error.response.data[0]);
        }
      }

      if (p2dId) {
        editPromptToDesignData({ isProjectSaved: true }, +p2dId);
      }
      localStorage.setItem('sceneSaved', true);
    },
    [cesdkRef, dataObj], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onTemplateChange = useCallback(
    async (newTemplateId) => {
      const cesdk = cesdkRef.current;
      const result = await cesdk.engine.asset.findAssets(
        customAssetSourceId.templates,
      );
      const scene = result.assets.find(
        ({ innerId }) => innerId === newTemplateId,
      ).meta.uri;

      cesdk.engine.scene.loadFromURL(scene);
      updateEditableData({ templateId: newTemplateId });
    },
    [cesdkRef], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onDownload = useCallback((scene) => {
    if (p2dId) {
      editPromptToDesignData({ isExportUsed: true }, +p2dId);
    }

    const a = document.createElement('a');

    a.href = `data:application/octet-stream,${encodeURIComponent(scene)}`;
    a.download = `${editableData.current.name.split(' ').join('_')}.scene`;

    a.click();

    if (localStorage.getItem('isFileExported') === 'null') {
      mixpanel.identify(userProfile.id);

      mixpanel.track('Export Design', {
        design_token: new Date().valueOf(),
        format_downloaded: 'designproject',
        origin_type: originType,
        time_spent: convertTimeSpent(
          new Date().getTime() - timeOfCesdkInitialization.getTime(),
        ),
        number_of_edits: localStorage.getItem('editsAmount'),
      });
    }

    localStorage.setItem('isFileExported', true);
  }, []);

  const onImageUpload = useCallback(async (file) => {
    const maxAllowedMbSize = 40;
    const maxAllowedBSize = maxAllowedMbSize * 1024 * 1024;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    $(
      'div[class*=UBQ_Dialog-module__block]',
      $('#root-shadow')[0].shadowRoot,
    ).show();

    if (file.size > maxAllowedBSize) {
      return Promise.reject(
        `Image size should be less than ${maxAllowedMbSize}Mb`,
      );
    }

    if (!allowedExtensions.exec(file.name)) {
      return Promise.reject('File type is not supported');
    }

    try {
      const formData = new FormData();

      formData.append('resource', file);

      const response = await createUploadedImage(formData);

      $(
        'div[class*=UBQ_Dialog-module__block]',
        $('#root-shadow')[0].shadowRoot,
      ).hide();

      cesdkRef.current.refetchAssetSources('customAssetSource');

      return Promise.resolve({
        id: String(response.id),
        locale: 'en',
        label: response.description || undefined,
        tags: response.assetTags
          ? response.assetTags.map((tag) => tag)
          : undefined,
        meta: {
          blockType: '//ly.img.ubq/graphic',
          uri: response.resource || (response.src && response.src.original),
          thumbUri:
            response.preview || (response.src && response.src.medium) || '',
          width: 128,
          height: 128,
        },
      });
    } catch (error) {
      let errorMessage = 'Image upload error';

      if (error.response) {
        errorMessage = Object.values(error.response.data).flat().join('\n');
      }

      return Promise.reject(errorMessage);
    }
  }, []);

  const onUploadedImageDelete = async (innerId) => {
    const shadowRootEl = $('#root-shadow')[0].shadowRoot;
    await deleteUploadedImageById(innerId);

    cesdkRef.current.refetchAssetSources('customAssetSource');

    const menuBtn = $("[data-cy='asset-library-dock']", shadowRootEl)
      .find('button')
      .filter((_, ell) =>
        $(ell).attr('class').includes('UBQ_Button__ubq-variant_Selected'),
      );

    const cancelModalBtn = $(
      $("[name='close-modal']", $('#root-shadow')[0].shadowRoot)[0],
    );

    menuBtn.click();
    cancelModalBtn.click();

    setTimeout(() => {
      menuBtn.click();

      setTimeout(() => {
        const sidebarEl = $('#ubq-portal-container_panelLeft', shadowRootEl);
        const observer = new MutationObserver(() => {
          const gridBlock = $(
            `[data-cy^="assetLibraryCard-${innerId}"]`,
            shadowRootEl,
          )
            .parent()
            .parent();

          if (!gridBlock.length) return;

          gridBlock.hide();
          observer.disconnect();
        });

        observer.observe(sidebarEl[0], {
          attributes: true,
          subtree: true,
        });

        $.modal.close();
      }, 200);
    }, 400);
  };

  const onImageDownload = useCallback(async () => {
    if (p2dId) {
      editPromptToDesignData({ isExportUsed: true }, +p2dId);
    }

    const archive = await cesdkRef.current.engine.scene.saveToArchive();

    const a = document.createElement('a');
    const linkHref = window.URL.createObjectURL(archive);

    a.href = linkHref;
    a.download = `${editableData.current.name.split(' ').join('_')}.zip`;

    a.click();
    window.URL.revokeObjectURL(linkHref);
  }, []);

  const onPagesPreviewDownload = useCallback(async (isJpg = false) => {
    const format = isJpg ? 'jpg' : 'png';

    if (p2dId) {
      editPromptToDesignData({ isExportUsed: true }, +p2dId);
    }

    const cesdk = cesdkRef.current;
    const pagesId = cesdk.engine.block.findByType('page');

    const previewFiles = await Promise.all(
      pagesId.map((innerId) => generateImageByBlockId(cesdk, innerId, isJpg)),
    );

    if (previewFiles.length > 1) {
      generateZip(previewFiles);
    } else {
      const a = document.createElement('a');
      const linkHref = window.URL.createObjectURL(previewFiles[0]);

      a.href = linkHref;
      a.download = `${editableData.current.name.split(' ').join('_')}.${
        isJpg ? 'jpg' : 'png'
      }`;

      a.click();
      window.URL.revokeObjectURL(linkHref);

      if (localStorage.getItem(`${format}Exported`) === 'null') {
        mixpanel.identify(userProfile.id);

        mixpanel.track('Export Design', {
          design_token: new Date().valueOf(),
          format_downloaded: isJpg ? 'jpg' : 'png',
          origin_type: originType,
          time_spent: convertTimeSpent(
            new Date().getTime() - timeOfCesdkInitialization.getTime(),
          ),
          number_of_edits: localStorage.getItem('editsAmount'),
        });
      }

      localStorage.setItem(`${format}Exported`, true);
    }
  }, []);

  // const replaceImage = (cesdk, selectedImageId, newUrl) => {
  //   cesdk.engine.block.setString(selectedImageId, 'image/imageFileURI', newUrl);
  //   cesdk.engine.block.resetCrop(selectedImageId);
  // };

  // const setBlockMetadata = (cesdk, blockId, key, value) => {
  //   cesdk.engine.block.setMetadata(blockId, key, value);
  // };

  // const onRemoveImageBackground = async () => {
  //   const cesdk = cesdkRef.current;
  //   const selectedImageId = cesdk.engine.block.findAllSelected()[0];
  //   const image = await generateImageByBlockId(cesdk, selectedImageId);
  //   const isBackgroundRemovedKey = 'isBackgroundRemoved';

  //   !cesdk.engine.block
  //     .findAllMetadata(selectedImageId)
  //     .includes(isBackgroundRemovedKey) &&
  //     removeImageBackground(image).then((response) => {
  //       replaceImage(cesdk, selectedImageId, response.image);

  //       cesdk.engine.editor.addUndoStep();

  //       setBlockMetadata(
  //         cesdk,
  //         selectedImageId,
  //         isBackgroundRemovedKey,
  //         'true',
  //       );
  //     });
  // };

  // const onShareProject = async () => {
  //   // check if project is saved
  //   if (isSceneChangedRef.current) {
  //     jqueryModal({
  //       description: 'Please, save a file before to request a help',
  //     });

  //     return;
  //   }

  //   const formData = new FormData();

  //   formData.append('user_project_id', projectId);

  //   try {
  //     // get hash
  //     const { scene } = await createShareProjectHash(formData);
  //     // create share project link
  //     const shareLink = `${window.location.origin}${window.location.pathname}share/${scene}`;

  //     // copy share lint to the clipboard
  //     await navigator.clipboard.writeText(shareLink);

  //     // show notification
  //     jqueryModal({
  //       description: `Share link is copied to the clipboard <br/> <br/>${shareLink}`,
  //     });
  //   } catch (err) {
  //     console.error('Failed to copy: ', err);
  //   }
  // };

  const changeDefaultInsertValues = (cesdkEngine) => {
    const cesdkDefaultInsertVariables = cesdkEngine.variable.findAll();
    const customInsertValues = [
      { name: 'Address', value: userProfile.address || '123 Coolab Ave' },
      { name: 'City', value: userProfile.city || 'Earth' },
      { name: 'Firstname', value: userProfile.firstName || 'Firstname' },
      { name: 'Lastname', value: userProfile.lastName || 'Lastname' },
      { name: 'Company', value: userProfile.company || 'coolab.ai' },
    ];

    cesdkDefaultInsertVariables.forEach((variable) =>
      cesdkEngine.variable.remove(variable),
    );

    customInsertValues.forEach(({ name, value }) => {
      cesdkEngine.variable.setString(name, value);
    });
  };

  const setFormatIntoQuery = (formatId) => {
    searchParams.set('formatId', formatId);
    setSearchParams(searchParams);
  };

  const onExportPDF = (blobs) => {
    if (p2dId) {
      editPromptToDesignData({ isExportUsed: true }, +p2dId);
    }

    if (localStorage.getItem('pdfExported') === 'null') {
      mixpanel.identify(userProfile.id);

      mixpanel.track('Export Design', {
        design_token: new Date().valueOf(),
        format_downloaded: 'pdf',
        origin_type: originType,
        time_spent: convertTimeSpent(
          new Date().getTime() - timeOfCesdkInitialization.getTime(),
        ),
        number_of_edits: localStorage.getItem('editsAmount'),
      });
    }

    const url = URL.createObjectURL(blobs[0]);

    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = `${editableData.current.name
      .split(' ')
      .join('_')}.pdf`;
    anchorElement.target = '_blank';

    anchorElement.click();
    anchorElement.remove();
    URL.revokeObjectURL(url);

    localStorage.setItem('pdfExported', true);
  };

  const onQRCodeModalOpen = () => {
    setIsQRCodeModalOpen(true);
  };

  const onQRCodeModalClose = () => {
    setIsQRCodeModalOpen(false);
  };

  const onHandleReplaceableLayers = (layerId) => {
    const replaceableAssets = JSON.parse(
      searchParams.get('replaceable') || '[]',
    );

    const filteredReplaceableAssets = replaceableAssets.includes(layerId)
      ? replaceableAssets.filter((innerLayerId) => innerLayerId !== layerId)
      : [...replaceableAssets, layerId];

    const searchParamsToSet = replaceableAssets.length
      ? filteredReplaceableAssets
      : [layerId];

    searchParams.set('replaceable', JSON.stringify(searchParamsToSet));
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const getScenePageFormats = async () => {
      const pageFormats = await getPageFormats();

      searchParams.set(
        'formatId',
        pageFormats.find((format) => format.name === 'Post Square').id,
      );
      setSearchParams(searchParams);
      setScenePageFormats((prevState) => [...prevState, ...pageFormats]);
    };

    getScenePageFormats();
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const response = await getProfile();
      dispatch(profileActions.setProfile(response));
    };

    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let response;

      let title = 'New Design';

      if (isProjectsOrigin || isP2DOrigin || redirectedFromStudio) {
        response = await getDigitalGoodById(sceneId);
        if (response.hasOwnProperty('name')) {
          title = response.name;
        }
        if (generatedByP2D) {
          const sceneBlob = await fetch(response.resource).then(
            (innerResponse) => innerResponse.blob(),
          );
          const sceneFromBlob = await sceneBlob.text();
          const transformedScene = getFixedScene(sceneFromBlob);

          setTimeout(() => {
            cesdkRef.current.load(transformedScene);
          }, 500);
        }
      } else if (isTemplatesOrigin && sceneId) {
        response = await getDigitalGoodTemplateById(sceneId);
        if (response.hasOwnProperty('slug')) {
          title = response.slug;
        }
      } else {
        response = {};
      }

      setDataObj(response);
      updateEditableData({ name: title });
    };

    // fetch template or digital good data
    fetchData();
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const cesdk = cesdkRef.current;

    if (!cesdk?.save) return;

    (async () => {
      const currentScene = await cesdk.save();

      setIsSceneChanged(savedScene !== currentScene);
    })();
  }, [savedScene, cesdkRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onPageLeave = (e) => {
      if (!isSceneChanged) return;

      e.preventDefault();

      // For IE and Firefox prior to version 4
      // eslint-disable-next-line no-param-reassign
      e.returnValue = '';

      // For Safari
      return '';
    };

    window.addEventListener('beforeunload', onPageLeave);

    return () => {
      window.removeEventListener('beforeunload', onPageLeave);
    };
  }, [isSceneChanged]);

  useEffect(() => {
    const retrieveAllImageAssetsCategories = async () => {
      const assetsCategoriesFromImages = await getAssetsCategoriesByLibrary(
        assetCategoryLibraryType.images,
      );

      setImageAssetsCategories((prevState) => [
        ...prevState,
        ...assetsCategoriesFromImages,
      ]);
    };

    retrieveAllImageAssetsCategories();
  }, []);

  useEffect(() => {
    getCustomFonts().then((innerCustomFonts) => {
      setCustomFonts(innerCustomFonts);
    });
    getCustomFonts('RECOMMENDED').then((innerCustomFonts) => {
      setRecommendedFonts((prevState) => [...prevState, ...innerCustomFonts]);
    });
    getCustomFonts('INTERNATIONAL').then((innerCustomFonts) => {
      setInternationalFonts((prevState) => [...prevState, ...innerCustomFonts]);
    });

    getDefaultFonts('RECOMMENDED').then((innerCustomFonts) => {
      setRecommendedFonts((prevState) => [
        ...prevState,
        ...innerCustomFonts.map((font) => ({ ...font, slug: font.font })),
      ]);
    });
    getDefaultFonts('INTERNATIONAL').then((innerCustomFonts) => {
      setInternationalFonts((prevState) => [
        ...prevState,
        ...innerCustomFonts.map((font) => ({ ...font, slug: font.font })),
      ]);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(changedBlocks).length > 3 && !isEditedMoreThanThreeThings) {
      editPromptToDesignData({ isExportUsed: true }, +p2dId);
      setIsEditedMoreThanThreeThings(true);
    }
  }, [changedBlocks, isEditedMoreThanThreeThings]);

  useEffect(() => {
    if (
      cesdkRef.current &&
      dataObj &&
      userProfile.permissions.length &&
      scenePageFormats.length &&
      imageAssetsCategories.length &&
      customFonts.length &&
      !document.querySelectorAll('div.ubq-public').length
    ) {
      CreativeEditorSDK.create(
        cesdkRef.current,
        getConfig({
          fileName: editableData.current.name,
          onLoad: () => uploadFileRef.current.click(),
          onSave,
          onDownload,
          onUpload: onImageUpload,
          onImageDownload,
          onPagesPreviewDownload,
          onUploadedImageDelete,
          isAllExportOptions: userProfile.permissions.includes(
            userPermissions.allExportOptions,
          ),
          imageAssetsCategories,
          photoAssetsCategories: imageAssetsCategories,
          customFonts,
          onExportPDF,
          isLayersVisible: userProfile.permissions.includes(
            userPermissions.backgroundRemoval,
          ),
          isReplaceableFeatureEnabled: userProfile.permissions.includes(
            userPermissions.isReplaceableFeatureEnabled,
          ),
          hasInternalAPIAccess: userProfile.permissions.includes(
            userPermissions.hasInternalAPIAccess,
          ),
        }),
      ).then(async (cesdk) => {
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });

        localStorage.setItem('sceneSaved', null);
        localStorage.setItem('isFileExported', null);
        localStorage.setItem('pngExported', null);
        localStorage.setItem('jpgExported', null);
        localStorage.setItem('pdfExported', null);

        setTimeout(() => {
          cesdk.engine.scene.setDesignUnit('Pixel');
        }, 1000);

        // if (userProfile.permissions.includes('BACKGROUND_REMOVAL')) {
        //   await cesdk.unstable_addPlugin(
        //     BackgroundRemovalPlugin({
        //       ui: { locations: 'canvasMenu' },
        //     }),
        //   );
        // }

        cesdk.ui.setDockOrder(
          cesdk.ui.getDockOrder().map((entry) => {
            if (cesdkLibraryIcons[entry.key]) {
              return {
                ...entry,
                icon: cesdkLibraryIcons[entry.key],
              };
            }

            return entry;
          }),
        );

        if (p2dId) {
          setTimeout(() => {
            cesdk.engine.editor.onHistoryUpdated(() => {
              setChangedBlocks((prevState) => ({
                ...prevState,
                [cesdk.engine.block.findAllSelected()[0]]: true,
              }));
            });
          }, 2000);
        }

        const customTemplatesSource = {
          id: 'my-templates',
          findAssets: (query) =>
            findCustomAssets(query, customAssetsInfo.templates),
          async applyAsset(assetResult) {
            cesdk.engine.scene.loadFromURL(assetResult.meta.uri);
          },

          async applyAssetToBlock(assetResult, block) {
            cesdk.engine.asset.defaultApplyAssetToBlock(assetResult, block);
          },
        };

        const customImagesSource = {
          id: customAssetsInfo.images.sourceId,
          findAssets: (query) =>
            findCustomAssets(query, customAssetsInfo.images),

          async applyAssetToBlock(assetResult, block) {
            cesdk.engine.asset.defaultApplyAssetToBlock(assetResult, block);
          },
        };

        const customPhotosSource = {
          id: customAssetsInfo.pexels.sourceId,
          findAssets: (query) =>
            findCustomAssets(query, customAssetsInfo.pexels),

          async applyAssetToBlock(assetResult, block) {
            cesdk.engine.asset.defaultApplyAssetToBlock(assetResult, block);
          },
        };

        const customShapesSource = {
          id: customAssetsInfo.shapes.sourceId,
          findAssets: (query) =>
            findCustomAssets(query, customAssetsInfo.shapes),

          async applyAssetToBlock(assetResult, block) {
            cesdk.engine.asset.defaultApplyAssetToBlock(assetResult, block);
          },
        };

        const customUploadsSource = {
          id: customAssetSourceId.customUploads,
          findAssets: (query) =>
            findCustomAssets(query, customAssetsInfo.customUploads),

          async applyAssetToBlock(assetResult, block) {
            cesdk.engine.asset.defaultApplyAssetToBlock(assetResult, block);
          },
          removeAsset: (id) => onUploadedImageDelete(id),
        };

        const customSystemImagesSource = {
          id: customAssetSourceId.systemImages,
          findAssets: (query) =>
            findCustomAssets(query, customAssetsInfo.systemImages),

          async applyAssetToBlock(assetResult, block) {
            cesdk.engine.asset.defaultApplyAssetToBlock(assetResult, block);
          },
        };

        cesdk.engine.asset.addSource(customTemplatesSource);
        cesdk.engine.asset.addSource(customImagesSource);
        cesdk.engine.asset.addSource(customPhotosSource);
        cesdk.engine.asset.addSource(customShapesSource);
        cesdk.engine.asset.addSource(customUploadsSource);
        cesdk.engine.asset.addSource(customSystemImagesSource);

        let editsAmount = 0;
        cesdk.engine.editor.onHistoryUpdated(() => {
          {
            editsAmount++;
            // '-2' because cesdk while initializing triggers twice
            localStorage.setItem('editsAmount', editsAmount - 2);
          }
        });

        cesdk.addDefaultAssetSources('ly.img.vectorpath');
        // not needed, but might be useful later
        // cesdk.addDemoAssetSources();
        // cesdk.addDefaultAssetSources();

        changeDefaultInsertValues(cesdk.engine);

        cesdkRef.current = cesdk;

        // cesdk.engine.editor.onHistoryUpdated(() => {
        //   setTimeout(onSave, 5000);
        // });

        // might be used later
        // if (!(projectId || templateId)) {
        //   autoOpenTemplatesDock();
        // }

        let projectBtnLabel = 'Projects';

        if (isTemplatesOrigin && sceneId) {
          projectBtnLabel = 'Templates';
        }

        if (
          redirectedFromStudio &&
          userProfile.permissions.includes(STUDIO_TOOL_PERMISSION)
        ) {
          projectBtnLabel = 'Studio';
        }

        injectProjectBtn(projectBtnLabel, () => {
          if (isTemplatesOrigin && sceneId) {
            window.location.href = '/templates/';
          } else if (
            redirectedFromStudio &&
            userProfile.permissions.includes(STUDIO_TOOL_PERMISSION)
          ) {
            window.location.href = '/tool/studio';
          } else {
            window.location.href = '/projects/';
          }
        });
        injectEditableTitle((title) => updateEditableData({ name: title }));
        // injectShareLinkButton(onShareProject);
        modifyFontDropdown(recommendedFonts, internationalFonts);
        initSidebarCustomLogic({
          onTemplateChange,
          getUploadImagesCount: async () => {
            await new Promise((resolve) => {
              setTimeout(resolve, 300);
            });
            const shadowRootEl = $('#root-shadow')[0].shadowRoot;

            const uploadedImgElements = $(
              `#assetLibraryCard-${customAssetSourceId.uploads}`,
              shadowRootEl,
            );

            return uploadedImgElements.length;

            /* sdk bug: every time triggers request to the server */
            // const result = await cesdk.engine.asset.findAssets(
            //   customAssetSourceId.uploads,
            // );

            // return result.assets.length;
          },
          cesdk: cesdkRef.current,
          pageFormats: scenePageFormats,
          setFormatIntoQuery,
          onQRCodeModalOpen,
          userProfile,
          onHandleReplaceableLayers,
        });
        fixRightSidebarPosition();
        hideInspectorBarPagesSectionElements();
        removePlaceholder();
        removeInsertVariablesSection();
        hideExpandDefaultColorsButton();
        // appendCustomButtonToInspectorBar(
        //   'Remove Background',
        //   onRemoveImageBackground,
        // );
        applyHorizontalGapForTemplatesElement();
        hidePreviewButton();
        userProfile.permissions.includes(userPermissions.randomizeTemplate) &&
          injectRandomizeTemplateButton(cesdk, dataObj.templateGenre);
        if (p2dId) {
          injectReRollImagesButton(p2dId, cesdk);
        }
        adjustSceneAppColours();
        adjustSceneBottomSection();
        // not needed, but might be useful later
        // const uploadImageSource = {
        //   id: customAssetsInfo.uploads.sourceId,
        //   findAssets: async (query) => {
        //     const response = await findCustomAssets(
        //       query,
        //       customAssetsInfo.uploads,
        //     );

        //     return response;
        //   },
        // };

        if (dataObj.resource) {
          // temporary fix for COOL-187
          try {
            const sceneBlob = await fetch(dataObj.resource).then((response) =>
              response.blob(),
            );
            const sceneFromBlob = await sceneBlob.text();
            const transformedScene = getFixedScene(sceneFromBlob);

            cesdk.load(transformedScene);
            setSavedScene(transformedScene);
          } catch (err) {
            console.error(err.name, err.message);
          }
          // cesdk.loadFromURL(dataObj.resource);
        } else {
          fetch('/static/samples/blank.scene').then((resp) => {
            cesdk.loadFromURL(resp.url);
          });
        }

        setIsSdkInitialized(true);

        // not needed, but might be useful later
        // cesdk.engine.asset.removeSource(customAssetsInfo.uploads.sourceId);
        // cesdk.engine.asset.addSource(uploadImageSource);

        if (dataObj.replaceableLayers && dataObj.replaceableLayers.length) {
          searchParams.set(
            'replaceable',
            JSON.stringify(dataObj.replaceableLayers),
          );
          setSearchParams(searchParams);
        }
      });
    }
  }, [
    cesdkRef,
    dataObj,
    userProfile,
    scenePageFormats,
    imageAssetsCategories,
    customFonts,
    recommendedFonts,
    internationalFonts,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  const customEditorStyle = generateCustomThemeStyle(
    // replace with dynamic color hexes if needed
    generateCustomTheme('#000000', '#000000', '#ffffff'),
  );

  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return <UnsupportedDeviceScreen />;
  }

  return (
    <>
      <UnsupportedDeviceScreen />
      <div className="scene-app-container">
        <style>{customEditorStyle}</style>
        <div
          ref={cesdkRef}
          style={{
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
          }}
        />
        <input
          type="file"
          ref={uploadFileRef}
          onChange={onImportFile}
          className="hidden"
          accept=".scene"
        />
        {isSdkInitialized && (
          <BottomActions
            help={{
              getPreviewFile,
              getData: getDesignerHelpCategories,
              requestHelp: requestDesignerHelp,
              localStorageKey: 'canvasHelpData',
              isSceneChanged,
            }}
            feedback={{
              createFeedback,
              getPreviewFile,
              getData: getFeedbackCategories,
            }}
            qrCode={{
              onQRCodeModalClose,
              isQRCodeModalOpen,
              cesdk: cesdkRef?.current,
            }}
          />
        )}
      </div>
    </>
  );
}

export default CanvasEditor;

import React, { useState, useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cn from 'classnames';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';

// components
import FolderContextMenu from './FolderContextMenu';

// helpers
import {
  STUDIO_FOLDER,
  STUDIO_PROJECTS_CARD,
} from '../../../helpers/constants';

// hooks
import useClickOutside from '../../../hooks/useClickOutside';

// types
import { DragItem, IStudioProjectsFolder } from '../../../types/studio.types';

// styles
import '../../../styles/studio/studio-content.scss';

interface IFolderRenameFormValue {
  name: string;
}

const ProjectsFolder = ({
  projectFolder,
  onProjectMoveToFolder,
  onFolderOpen,
  isProjectDragging,
  onFolderRename,
  onProjectFolderDuplicate,
  onProjectFolderDelete,
  index,
  moveFolder,
  id,
  checkIsFolderDragging,
  isFolderDuplicateLoading,
  rightClickedFolder,
  onSelectRightClickedFolder,
}: IStudioProjectsFolder) => {
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [contextMenuXYPosition, setContextMenuXYPosition] = useState({
    x: 0,
    y: 0,
  });
  const [isFolderRenameModalVisible, setIsFolderRenameModalVisible] =
    useState(false);
  const [isFolderDeleteModalVisible, setIsFolderDeleteModalVisible] =
    useState(false);

  const folderRef = useRef<HTMLDivElement>(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IFolderRenameFormValue>();

  const [{ isOver }, folderDrop] = useDrop({
    accept: [STUDIO_PROJECTS_CARD, STUDIO_FOLDER],
    hover(item: DragItem) {
      if (!folderRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // if to reorder projects while dragging should be any conditions - put it below

      // Determine rectangle on screen
      // const hoverBoundingRect = cardRef.current?.getBoundingClientRect();
      // Get vertical middle
      // const hoverMiddleY =
      //   (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      // const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      // const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return;
      // }
      // // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }

      moveFolder(dragIndex, hoverIndex as number);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex as number;
    },
    drop(_item: DragItem, monitor) {
      const didDrop = monitor.didDrop();
      const dragIndex = _item.index;
      const hoverIndex = index;

      if (didDrop) {
        return;
      }

      if (_item.isFolder) {
        moveFolder(dragIndex, hoverIndex as number);
      } else {
        onProjectMoveToFolder(+_item?.id, projectFolder?.id);
      }

      // eslint-disable-next-line no-param-reassign
      _item.index = hoverIndex as number;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: STUDIO_FOLDER,
    item: () => {
      return { id, index, isFolder: true };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    checkIsFolderDragging(isDragging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const handleCloseContextMenu = () => {
    setIsContextMenuVisible(false);
  };

  const handleRightMouseClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsContextMenuVisible(false);

    const positionChange = {
      x: event.pageX,
      y: event.pageY,
    };

    setContextMenuXYPosition(positionChange);
    setIsContextMenuVisible(true);
    onSelectRightClickedFolder(id);
  };

  const openFolderRenameModal = () => {
    setIsFolderRenameModalVisible(true);
  };

  const closeFolderRenameModal = () => {
    setIsFolderRenameModalVisible(false);
  };

  const onRenameFormSubmit = (formValues: IFolderRenameFormValue) => {
    onFolderRename({ id: projectFolder.id, ...formValues });
    setIsFolderRenameModalVisible(false);
  };

  const duplicateProjectFolder = () => {
    onProjectFolderDuplicate(projectFolder.id);
    setIsContextMenuVisible(false);
    onSelectRightClickedFolder(null);
  };

  const openFolderDeleteModal = () => {
    setIsFolderDeleteModalVisible(true);
  };

  const closeFolderDeleteModal = () => {
    setIsFolderDeleteModalVisible(false);
  };

  const onDeleteProjectFolder = () => {
    onProjectFolderDelete(projectFolder.id);
    setIsFolderDeleteModalVisible(false);
  };

  useClickOutside(
    folderRef,
    () => {
      setIsContextMenuVisible(false);
    },
    'context-menu',
  );

  useEffect(() => {
    if (!isContextMenuVisible) {
      onSelectRightClickedFolder(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContextMenuVisible]);

  drag(folderDrop(folderRef));

  return (
    <>
      <div ref={folderRef}>
        <div
          className={cn('studio-projects-folders--folder', {
            'drag-hover': isOver,
            hovered: rightClickedFolder === id,
          })}
          ref={folderDrop}
          onClick={(event) => {
            onFolderOpen(event, projectFolder);
            handleCloseContextMenu();
          }}
          onContextMenu={handleRightMouseClick}
        >
          {!(isProjectDragging || isOver) && (
            <img
              src="/static/img/icons/project-folder.svg"
              alt="folder"
              width={72}
              height={48}
              className="studio-projects-folders--folder--icon"
            />
          )}
          {(isProjectDragging || isOver) && (
            <img
              src="/static/img/icons/project-folder-lighter.svg"
              alt="folder"
              width={72}
              height={48}
              className="studio-projects-folders--folder--icon"
            />
          )}
          <div className="studio-projects-folders--folder--details-container">
            <p className="studio-projects-folders--folder--details-container--title">
              {projectFolder.name}
            </p>
            <p
              className={cn(
                'studio-projects-folders--folder--details-container--designs-amount',
                {
                  'drag-hover': isOver,
                },
              )}
            >
              {projectFolder.projectTotal} designs
            </p>
          </div>
        </div>
      </div>
      <FolderContextMenu
        isVisible={isContextMenuVisible}
        position={contextMenuXYPosition}
        openFolderRenameModal={openFolderRenameModal}
        onProjectFolderDuplicate={duplicateProjectFolder}
        openFolderDeleteModal={
          projectFolder.projectTotal
            ? openFolderDeleteModal
            : onDeleteProjectFolder
        }
        isFolderDuplicateLoading={isFolderDuplicateLoading}
      />
      <Modal
        isOpen={isFolderRenameModalVisible}
        ariaHideApp={false}
        onRequestClose={closeFolderRenameModal}
        className="Modal__Content dark"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <div className="studio-project-modal">
          <p className="studio-project-modal--label">
            Confirm renaming of folder?
          </p>
          <form className="form" onSubmit={handleSubmit(onRenameFormSubmit)}>
            <input
              type="text"
              className="studio-project-modal--input"
              placeholder="Type folder name here..."
              {...register('name', {
                required: true,
                value: projectFolder.name,
              })}
            />
            {errors.name && (
              <span className="field-error">Field is required</span>
            )}
            <div className="studio-project-modal--btns-container">
              <button
                className="studio-project-modal--btns-container--btn cancel"
                type="button"
                onClick={closeFolderRenameModal}
              >
                cancel
              </button>
              <button
                className="studio-project-modal--btns-container--btn submit"
                type="submit"
              >
                rename
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={isFolderDeleteModalVisible}
        ariaHideApp={false}
        onRequestClose={closeFolderDeleteModal}
        className="Modal__Content dark"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <div className="studio-project-modal">
          <p className="studio-project-modal--label">
            Delete this design project
          </p>
          <p className="studio-project-modal--info">
            Are you sure you want to delete this project?
          </p>
          <div className="studio-project-modal--btns-container">
            <button
              className="studio-project-modal--btns-container--btn cancel"
              type="button"
              onClick={closeFolderDeleteModal}
            >
              cancel
            </button>
            <button
              className="studio-project-modal--btns-container--btn submit"
              type="button"
              onClick={onDeleteProjectFolder}
            >
              delete forever
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProjectsFolder;

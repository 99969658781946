import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';

// helpers
import { getToasterOptions } from '../../../helpers/toaster';

// api
import { getProjects } from '../../../api/projects';

// types
import { IStudioProject } from '../../../types/studio.types';
import {
  IFormatExtenderContentStep1,
  IFormatExtenderProject,
} from '../../../types/studio-format-extender.types';

// styles
import '../../../styles/studio/format-extender.scss';

const Project = ({
  project,
  handleSelectProject,
  selectedProject,
}: IFormatExtenderProject) => {
  return (
    <div
      className={cn('projects-content--projects-container--project', {
        active: selectedProject?.id === project?.id,
      })}
      onClick={() => handleSelectProject(project)}
    >
      <div className="projects-content--projects-container--project--image-container">
        <img
          src={project.preview}
          alt="project"
          className="projects-content--projects-container--project--image-container--image"
        />
      </div>
      <p className="projects-content--projects-container--project--title">
        {project.name}
      </p>
    </div>
  );
};

const FormatExtenderContentStep1 = ({
  selectedProject,
  handleSelectProject,
  onProceedFirstStep,
}: IFormatExtenderContentStep1) => {
  const [projects, setProjects] = useState<IStudioProject[]>([]);

  const isProjectsEmpty = !projects.length;

  useEffect(() => {
    getProjects()
      .then((projectsData) => {
        setProjects(projectsData.results);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with projects',
          getToasterOptions(),
        );
      });
  }, []);

  return (
    <div className="format-extender--content">
      <div className="projects-content">
        {isProjectsEmpty ? (
          <div className="projects-content empty">
            There are no projects yet
          </div>
        ) : (
          <>
            <div className="projects-content--projects-container">
              {projects.map((project) => (
                <Project
                  key={project.id}
                  project={project}
                  handleSelectProject={handleSelectProject}
                  selectedProject={selectedProject}
                />
              ))}
            </div>
            <div className="projects-content--btn-container">
              <span
                className={cn('projects-content--btn-container--wrapper', {
                  disabled: !selectedProject?.id,
                })}
              >
                <button
                  type="button"
                  className={cn(
                    'projects-content--btn-container--wrapper--proceed-btn',
                    {
                      disabled: !selectedProject?.id,
                    },
                  )}
                  onClick={onProceedFirstStep}
                >
                  next
                </button>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FormatExtenderContentStep1;

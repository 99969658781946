import React, { useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import format from 'date-fns/format';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SelectableGroup, createSelectable } from 'react-selectable';

// components
import GenreAssetsContextMenu from './GenreAssetsContextMenu';

// helpers
import generateZip from '../../../helpers/editor/generateZip';
import { convertImageUrlToFile } from '../../../helpers/common.helpers';
import { downloadImage } from '../../../helpers/image-download';
import { getToasterOptions } from '../../../helpers/toaster';

// hooks
import useClickOutside from '../../../hooks/useClickOutside';

// api
import {
  deleteLayer,
  getTemplatesGenres,
  getAssetsLayers,
  updateCustomLayer,
  // addNewCustomLayerImage,
  assetsUpdateReplaceableStatus,
} from '../../../api/assets';

// types
import { DropdownStylesType } from '../../../types/bottom-actions.types';
import {
  GenreAssetType,
  IGenreAsset,
  NewlyTriggeredAssetType,
  TemplateGenreType,
} from '../../../types/studio.types';
import { DropdownOptionType } from '../../../types/common.types';

// styles
import '../../../styles/studio/genre-assets.scss';

const darkDropdownStyles: DropdownStylesType = {
  input: (base) => ({
    ...base,
    color: '#eeeeee',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: () => ({ display: 'none' }),
  control: (baseStyles) => ({
    ...baseStyles,
    background: '#2e2e2e',
    border: '0.5px solid #242424',
    width: '100%',
    color: '#EEEEEE',
    boxShadow: 'none',
    borderRadius: '8px',
    ':hover': {
      border: '0.5px solid #888888',
    },
  }),
  multiValue: () => ({
    background: '#484848',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    color: '#eeeeee',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    gap: '6px',
    paddingLeft: '40px',
    paddingRight: '4px',
  }),
  multiValueRemove: (baseStyles) => ({
    ...baseStyles,
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': {
      background: '#eee',
      color: '#000',
    },
  }),
  menu: () => ({
    background: '#2e2e2e',
    position: 'absolute',
    width: '100%',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#ffffff',
    };
  },
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    position: 'relative',
    backgroundColor: isSelected || isFocused ? '#EAECF0' : undefined,
    paddingRight: 32,
    margin: 6,
    borderRadius: 6,
    width: '100%',
    color: '#EEEEEE',
    background: '#2e2e2e',
    textOverflow: 'ellipsis',
    maxWidth: '97%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ':hover': {
      background: isSelected ? '#2e2e2e' : '#ffffff',
      color: isSelected ? '#EEEEEE' : '#000000',
      cursor: isSelected ? 'default' : 'pointer',
    },

    ...(isSelected
      ? {
          ':after': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 10,
            margin: 'auto',
            background:
              'center no-repeat url(/static/img/icons/simple-white-tick.svg)',
            content: '" "',
            display: 'block',
            height: 14,
            width: 14,
          },
        }
      : {}),
  }),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ValueContainer = ({ children, ...props }: any) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
          <img
            src="/static/img/icons/search.svg"
            alt="search"
            className="genre-assets-search-icon"
          />
        )}
        {children}
      </components.ValueContainer>
    )
  );
};

const GenreAsset = ({
  asset,
  onAssetSelect,
  selectedAssets,
  deleteGenre,
  onAssetRightClick,
  onStartEditing,
  rightClickedAsset,
}: // onLockUnlockSuccess,
IGenreAsset) => {
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [contextMenuXYPosition, setContextMenuXYPosition] = useState({
    x: 0,
    y: 0,
  });

  const contextMenuRef = useRef(null);

  const handleRightMouseClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsContextMenuVisible(false);

    const positionChange = {
      x: event.pageX,
      y: event.pageY,
    };

    setContextMenuXYPosition(positionChange);
    setIsContextMenuVisible(true);
  };

  const onDownloadAsset = () => {
    downloadImage(asset.preview);
  };

  const onDeleteGenre = () => {
    deleteGenre(asset.id);
    setIsContextMenuVisible(false);
  };

  const onHandleStartEditing = () => {
    onStartEditing(true);
    setIsContextMenuVisible(false);
    onAssetRightClick(asset);
  };

  // const onLockUnlockAsset = () => {
  //   const data = new FormData();
  //   data.append('replaceable', `${!asset.replaceable}`);

  //   updateCustomLayer(data, asset.id)
  //     .then(({ replaceable }) => {
  //       onLockUnlockSuccess(asset.id, replaceable);
  //     })
  //     .catch(() => {
  //       toast.error(
  //         'Ooooops, error occurred with updating image',
  //         getToasterOptions(),
  //       );
  //     });
  //   setIsContextMenuVisible(false);
  // };

  useClickOutside(
    contextMenuRef,
    () => {
      setIsContextMenuVisible(false);
    },
    'context-menu',
  );

  return (
    <>
      <div
        className={cn(
          'genre-assets-content--content--assets-container--asset',
          {
            active: selectedAssets?.some(
              (innerSelectedAsset) => innerSelectedAsset.id === asset.id,
            ),
          },
        )}
        ref={contextMenuRef}
        onClick={() => onAssetSelect(asset)}
        onContextMenu={handleRightMouseClick}
      >
        <img
          src={asset.preview}
          alt="asset"
          className={cn(
            'genre-assets-content--content--assets-container--asset--image',
            {
              active: rightClickedAsset?.id === asset.id,
            },
          )}
        />
      </div>
      <GenreAssetsContextMenu
        position={contextMenuXYPosition}
        isShown={isContextMenuVisible}
        onDeleteGenre={onDeleteGenre}
        // onLockUnlockAsset={onLockUnlockAsset}
        onDownloadAsset={onDownloadAsset}
        onStartEditing={onHandleStartEditing}
      />
    </>
  );
};

const SelectableComponent = createSelectable(GenreAsset);

const GenreAssetsContent = () => {
  const [templatesGenres, setTemplatesGenres] = useState<TemplateGenreType[]>();
  const [genresAssets, setGenresAssets] = useState<GenreAssetType[]>([]);
  const [genreAssetsHasNext, setGenreAssetsHasNext] = useState(false);
  const [genreAssetsPageNumber, setGenreAssetsPageNumber] = useState(1);
  const [genresAssetsLoading, setGenresAssetsLoading] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState<GenreAssetType[] | null>(
    null,
  );
  const [selectedGenres, setSelectedGenres] = useState<number[]>([]);
  const [newlyTriggeredAssets, setNewlyTriggeredAssets] = useState<
    NewlyTriggeredAssetType[] | null
  >();
  const [rightClickedAsset, setRightClickedAsset] =
    useState<GenreAssetType | null>(null);
  const [isAssetEditing, setIsAssetEditing] = useState(false);
  const [isGenresDropdownOpened, setIsGenresDropdownOpened] = useState(false);
  const [isAssetGenreDropdownOpened, setIsAssetGenreDropdownOpened] =
    useState(false);

  const assetGenreSelect = useRef(null);
  const selectOptionsRef = useRef(null);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset: resetForm,
    watch,
    setFocus,
  } = useForm();

  useClickOutside(
    selectOptionsRef,
    () => {
      setIsGenresDropdownOpened(false);
    },
    'dropdown-menu',
  );

  useClickOutside(
    assetGenreSelect,
    () => {
      setIsAssetGenreDropdownOpened(false);
    },
    'asset-dropdown-menu',
  );

  // const isAssetLocked = !!watch('replaceable');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const onDrop = useCallback((acceptedFiles: any) => {
  //   const data = new FormData();
  //   data.append('resource', acceptedFiles[0]);

  //   addNewCustomLayerImage(data)
  //     .then((resp) => {
  //       setGenresAssets((prevState) =>
  //         Array.isArray(prevState) ? [...prevState, resp] : [resp],
  //       );
  //     })
  //     .catch(() => {
  //       toast.error(
  //         'Ooooops, error occurred with adding new image',
  //         getToasterOptions(),
  //       );
  //     });
  // }, []);

  // const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const requestGenreAssets = () => {
    setGenresAssetsLoading(true);
    getAssetsLayers('', genreAssetsPageNumber, 50, null, selectedGenres, true)
      .then((resp) => {
        setGenresAssets((prevState) => [...prevState, ...resp.results]);
        setGenreAssetsHasNext(!!resp.next);
        setGenreAssetsPageNumber((prevState) => prevState + 1);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with getting images',
          getToasterOptions(),
        );
      })
      .finally(() => {
        setGenresAssetsLoading(false);
      });
  };

  const updateAssetsReplaceableStatus = () => {
    if (!newlyTriggeredAssets?.length) {
      return;
    }

    const allowedToReplaceAssets: number[] = [];
    const disallowedToReplaceAssets: number[] = [];

    newlyTriggeredAssets.forEach((innerAsset) => {
      innerAsset.replaceable
        ? allowedToReplaceAssets.push(innerAsset.id)
        : disallowedToReplaceAssets.push(innerAsset.id);
    });

    const allowedToReplaceAssetsFormData = new FormData();
    const disallowedToReplaceAssetsFormData = new FormData();

    if (allowedToReplaceAssets.length) {
      allowedToReplaceAssetsFormData.append('replaceable', 'true');
      assetsUpdateReplaceableStatus(
        allowedToReplaceAssets,
        allowedToReplaceAssetsFormData,
      ).then(() => {
        toast.success('New assets set as replaceable', getToasterOptions());
      });
    }

    if (disallowedToReplaceAssets.length) {
      disallowedToReplaceAssetsFormData.append('replaceable', 'false');
      assetsUpdateReplaceableStatus(
        disallowedToReplaceAssets,
        disallowedToReplaceAssetsFormData,
      ).then(() => {
        toast.success('New assets set as irreplaceable', getToasterOptions());
      });
    }

    const newlyTriggeredAssetsIds = [
      ...allowedToReplaceAssets,
      ...disallowedToReplaceAssets,
    ];

    setGenresAssets((prevState) => {
      return prevState.map((innerAsset) => {
        if (newlyTriggeredAssetsIds.includes(innerAsset.id)) {
          return { ...innerAsset, replaceable: !innerAsset.replaceable };
        }

        return innerAsset;
      });
    });

    setNewlyTriggeredAssets(null);
  };

  useEffect(() => {
    getTemplatesGenres()
      .then((resp) => {
        setTemplatesGenres(resp);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with getting templates genres',
          getToasterOptions(),
        );
      });
  }, []);

  useEffect(() => {
    requestGenreAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGenres]);

  useEffect(() => {
    if (genresAssets.length) {
      const replaceableAssets: GenreAssetType[] = [];
      genresAssets.forEach((innerAsset) => {
        if (innerAsset.replaceable) {
          replaceableAssets.push(innerAsset);
        }
      });

      if (replaceableAssets.length) {
        setSelectedAssets(replaceableAssets);
      }
    }
  }, [genresAssets]);

  useEffect(() => {
    if (rightClickedAsset) {
      resetForm({
        name: rightClickedAsset.name,
        tags:
          rightClickedAsset.assetTags?.map((tag) => tag.name).join(',') || null,
        templateGenres: rightClickedAsset.templateGenres?.map(
          (genre) => genre.id,
        ),
        replaceable: rightClickedAsset.replaceable,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightClickedAsset]);

  const downloadAllGenresAssets = async () => {
    const genreAssetsImages = genresAssets?.map(async (asset, index) => {
      const file = await convertImageUrlToFile(asset.preview, index);

      return file;
    });
    const files = await Promise.all(genreAssetsImages);

    generateZip(files);
  };

  const onAssetSelect = (asset: GenreAssetType) => {
    const selectedAssetsIds =
      selectedAssets && selectedAssets.map((innerAsset) => innerAsset.id);

    // proper styling
    if (selectedAssetsIds && selectedAssetsIds.includes(asset.id)) {
      // deselect
      const filteredAssets = selectedAssets.filter(
        (innerSelectedAsset) => innerSelectedAsset.id !== asset.id,
      );
      setSelectedAssets(filteredAssets);
    } else {
      setSelectedAssets((prevState) =>
        !prevState ? [asset] : [...prevState, asset],
      );
    }

    // proper api handling
    const existingAssetInNewlyTriggered = newlyTriggeredAssets?.find(
      (innerAsset) => innerAsset.id === asset.id,
    );

    if (existingAssetInNewlyTriggered) {
      setNewlyTriggeredAssets((prevState) => [
        ...(prevState as NewlyTriggeredAssetType[]).filter(
          (innerAsset) => innerAsset.id !== existingAssetInNewlyTriggered.id,
        ),
        {
          id: existingAssetInNewlyTriggered.id,
          replaceable: !existingAssetInNewlyTriggered.replaceable,
        },
      ]);
    } else {
      setNewlyTriggeredAssets((prevState) =>
        prevState
          ? [...prevState, { id: asset.id, replaceable: !asset.replaceable }]
          : [{ id: asset.id, replaceable: !asset.replaceable }],
      );
    }
  };

  const findSelectedGenres = (templateGenres: number[]) => {
    const genresNames = [];
    for (let i = 0; i < templateGenres.length; i++) {
      genresNames.push(
        templatesGenres?.find((genre) => genre.id === templateGenres[i])?.name,
      );
    }

    return genresNames;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormSubmit = (formData: any) => {
    const { name, replaceable, tags, templateGenres } = formData;
    const data = new FormData();

    const templatesGenreNames = findSelectedGenres(templateGenres);

    data.append('name', name);
    data.append('replaceable', replaceable as unknown as string);
    data.append('template_genres', templatesGenreNames.join(','));
    data.append('asset_tags', tags.split(',').join(','));

    updateCustomLayer(data, rightClickedAsset?.id)
      .then(() => {
        toast.success('Layer was successfully updated', getToasterOptions());
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with updating image',
          getToasterOptions(),
        );
      });
  };

  const onAssetRightClick = (asset: GenreAssetType) => {
    setRightClickedAsset(asset);
  };

  const onCloseEditSection = () => {
    setRightClickedAsset(null);
  };

  const onStartEditing = (isEditing: boolean) => {
    setIsAssetEditing(isEditing);
  };

  const onGenreAssetDelete = (id: number) => {
    deleteLayer(id)
      .then(() => {
        setGenresAssets((prevState) =>
          prevState?.filter((asset) => asset.id !== id),
        );

        if (rightClickedAsset?.id === id) {
          setSelectedAssets(null);
        }
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with deleting image',
          getToasterOptions(),
        );
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onAssetImageReplace = (file: any) => {
    const templatesGenreNames =
      watch('templateGenres') && findSelectedGenres(watch('templateGenres'));

    const data = new FormData();
    data.append('name', watch('name'));
    data.append('replaceable', watch('replaceable'));
    data.append('resource', file[0]);

    if (templatesGenreNames) {
      data.append('template_genres', templatesGenreNames.join(','));
    }
    if (watch('tags')) {
      data.append('asset_tags', watch('tags').split(',').join(','));
    }

    updateCustomLayer(data, rightClickedAsset?.id)
      .then((resp) => {
        setGenresAssets((prevState) => [
          ...prevState.map((asset) => {
            if (asset.id === rightClickedAsset?.id) {
              return {
                ...asset,
                preview: resp.preview,
                resource: resp.resource,
              };
            }

            return asset;
          }),
        ]);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with updating image',
          getToasterOptions(),
        );
      });
  };

  const handleSelectionArea = (keys: number[]) => {
    if (keys.length) {
      const selectedAssetsIds = selectedAssets?.map(
        (innerSelectedAsset) => innerSelectedAsset.id,
      );
      const newAssetToMarkSelected = genresAssets.filter(
        (innerAsset) =>
          keys.includes(innerAsset.id) &&
          !selectedAssetsIds?.includes(innerAsset.id),
      );

      const selectedNewlyAddedAsset = newAssetToMarkSelected.map(
        (innerNewlyAsset) => ({ id: innerNewlyAsset.id, replaceable: true }),
      );

      setSelectedAssets((prevState) =>
        prevState
          ? [...prevState, ...newAssetToMarkSelected]
          : newAssetToMarkSelected,
      );

      setNewlyTriggeredAssets((prevState) =>
        prevState
          ? [...prevState, ...selectedNewlyAddedAsset]
          : selectedNewlyAddedAsset,
      );
    }
  };

  const onSelectClick = () => {
    setIsGenresDropdownOpened(true);
  };

  const onAssetGenreSelectClick = () => {
    setIsAssetGenreDropdownOpened(true);
  };

  // const onLockUnlockSuccess = (id: number, replaceable: boolean) => {
  //   setGenresAssets((prevState) =>
  //     prevState.map((asset) => {
  //       if (asset.id) {
  //         return { ...asset, replaceable };
  //       }

  //       return asset;
  //     }),
  //   );

  //   if (selectedAssets?.id === id) {
  //     setSelectedAssets((prevState) =>
  //       prevState ? { ...prevState, replaceable } : null,
  //     );
  //   }
  // };

  return (
    <SelectableGroup onEndSelection={handleSelectionArea}>
      <div className="genre-assets-content">
        <div className="genre-assets-content left-content full">
          <div className="genre-assets-content--topbar">
            <div
              className="genre-assets-content--topbar--select"
              onClick={onSelectClick}
            >
              <Select
                placeholder="Choose genre..."
                styles={darkDropdownStyles}
                isSearchable
                options={templatesGenres?.map((genre) => ({
                  id: genre.id,
                  label: genre.name,
                  value: `${genre.id}`,
                }))}
                isMulti
                isClearable={false}
                components={{ ValueContainer }}
                onChange={(option) => {
                  setSelectedGenres(
                    (option as unknown as DropdownOptionType[]).map(
                      (genre) => genre.id,
                    ),
                  );
                  setGenreAssetsPageNumber(1);
                  setGenresAssets([]);
                }}
                menuIsOpen={isGenresDropdownOpened}
                ref={selectOptionsRef}
              />
            </div>

            <div className="genre-assets--actions-container">
              <span
                className="genre-assets--actions-container--replaceable-btn"
                onClick={updateAssetsReplaceableStatus}
              >
                change replaceable status
              </span>
              <span
                className="genre-assets-content--topbar--download"
                onClick={downloadAllGenresAssets}
              >
                <span className="genre-assets-content--topbar--download--title">
                  download all
                </span>
                <div className="genre-assets-content--topbar--download--arrow" />
              </span>
            </div>
          </div>

          <div
            className="genre-assets-content--content--assets-container"
            id="scrollableDiv"
          >
            <InfiniteScroll
              dataLength={genresAssets.length}
              next={requestGenreAssets}
              hasMore={genreAssetsHasNext as unknown as boolean}
              loader={<h4 className="loading-text">Loading...</h4>}
              scrollableTarget="scrollableDiv"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
              }}
            >
              {genresAssets?.map((asset) => (
                <SelectableComponent
                  key={asset.id}
                  selected={asset.id}
                  selectableKey={asset.id}
                  asset={asset}
                  onAssetSelect={onAssetSelect}
                  selectedAssets={selectedAssets}
                  deleteGenre={onGenreAssetDelete}
                  onAssetRightClick={onAssetRightClick}
                  onStartEditing={onStartEditing}
                  rightClickedAsset={rightClickedAsset}
                >
                  {asset.description}
                </SelectableComponent>
              ))}
              {!genresAssets.length && !genresAssetsLoading && (
                <div className="no-templates-container">no templates</div>
              )}
            </InfiniteScroll>
          </div>

          {/* <div
          className="genre-assets-content--content--upload-container"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <button
            type="button"
            className="genre-assets-content--content--upload-container--btn"
          >
            choose a file or drag it here
          </button>
          <p className="genre-assets-content--content--upload-container--hint">
            Accepts .jpg, and .png
          </p>
        </div> */}
        </div>

        {rightClickedAsset && isAssetEditing && (
          <div className="genre-assets-content right-content">
            <form
              className="genre-assets-content--content--form"
              onSubmit={handleSubmit(onFormSubmit)}
            >
              <div className="selected-genre--header">
                <p className="genre-assets-content--content--form--label">
                  Asset Name
                </p>
                <div
                  className="selected-genre--header--close-btn"
                  onClick={onCloseEditSection}
                >
                  <img src="/static/img/icons/white-cross.svg" alt="close" />
                </div>
              </div>
              <input
                type="text"
                {...register('name', { required: true })}
                placeholder="name..."
                className="genre-assets-content--content--form--field"
                // disabled={isAssetLocked}
                onClick={() => {
                  setFocus('name');
                }}
              />
              {errors.name && (
                <span className="genre-assets-content--content--form--error">
                  Field is required
                </span>
              )}
              <p className="genre-assets-content--content--form--label">
                Asset Tags
              </p>
              <textarea
                {...register('tags', { required: true })}
                rows={5}
                placeholder="tags..."
                className="genre-assets-content--content--form--field"
                // disabled={isAssetLocked}
                onClick={() => {
                  setFocus('tags');
                }}
              />
              {errors.tags && (
                <span className="genre-assets-content--content--form--error">
                  Field is required
                </span>
              )}
              <p className="genre-assets-content--content--form--label">
                Categories
              </p>
              <div onClick={onAssetGenreSelectClick}>
                <Controller
                  control={control}
                  name="templateGenres"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    const formattedTemplatesGenres = templatesGenres?.map(
                      (genre) => ({
                        id: genre.id,
                        label: genre.name,
                        value: `${genre.id}`,
                      }),
                    );

                    const selectedValues = [];
                    for (let i = 0; i < value?.length; i++) {
                      selectedValues.push(
                        formattedTemplatesGenres?.find(
                          (genre) => genre.id === value[i],
                        ),
                      );
                    }

                    return (
                      <Select
                        placeholder="Choose genre..."
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        styles={darkDropdownStyles as any}
                        isSearchable
                        options={formattedTemplatesGenres}
                        isMulti
                        isClearable={false}
                        components={{ ValueContainer }}
                        value={selectedValues}
                        onChange={(val) => onChange(val.map((el) => el?.id))}
                        // isDisabled={isAssetLocked}
                        menuIsOpen={isAssetGenreDropdownOpened}
                        ref={assetGenreSelect}
                      />
                    );
                  }}
                />
              </div>

              {errors.templateGenres && (
                <span className="genre-assets-content--content--form--error m-0">
                  Dropdown option is required
                </span>
              )}

              <div>
                <p className="genre-assets-content--content--form--label">
                  Details
                </p>
                <div className="genre-assets-content--content--form--details">
                  <p>
                    File size: <span>{rightClickedAsset.resourceSize}kb</span>
                  </p>
                  <p>
                    Date Uploaded:{' '}
                    <span>
                      {format(
                        new Date(rightClickedAsset.createdAt),
                        'MMMM d, Y',
                      )}
                    </span>
                  </p>
                  <p>
                    Date Last Modified:{' '}
                    <span>
                      {format(
                        new Date(rightClickedAsset.updatedAt),
                        'MMMM d, Y',
                      )}
                    </span>
                  </p>
                  <p>
                    Last User:{' '}
                    <span>{rightClickedAsset?.user?.username || '-'}</span>
                  </p>
                </div>
              </div>

              <Dropzone
                onDrop={(acceptedFiles) => onAssetImageReplace(acceptedFiles)}
              >
                {({
                  getRootProps: getUploadReplacementRootProps,
                  getInputProps: getUploadReplacementInputProps,
                }) => (
                  <button
                    type="button"
                    className="genre-assets-content--content--form--upload-replacement"
                    // disabled={isAssetLocked}
                    {...getUploadReplacementRootProps()}
                  >
                    <input {...getUploadReplacementInputProps()} />
                    Upload Replacement
                  </button>
                )}
              </Dropzone>

              {/* <div className="genre-assets-content--content--form--asset-locked-container">
              <span className="genre-assets-content--content--form--asset-locked-container--label-container">
                <span className="genre-assets-content--content--form--asset-locked-container--label-container--label">
                  Replaceable
                </span>
                <input
                  type="checkbox"
                  className="genre-assets-content--content--form--asset-locked-container--label-container--checkbox"
                  {...register('replaceable')}
                />
              </span>
              <span className="genre-assets-content--content--form--asset-locked-container--hint">
                * asset will not be replaced during image swaps
              </span>
            </div> */}

              <button
                type="submit"
                className="genre-assets-content--content--form--save-btn"
                // disabled={isAssetLocked}
              >
                save
              </button>
            </form>
          </div>
        )}
      </div>
    </SelectableGroup>
  );
};

export default GenreAssetsContent;

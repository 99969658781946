import React, { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

// api
import { updateProject } from '../../../api/projects';

// types
import { IDesign, IReGenThirdStep } from '../../../types/studio-re-gen';

// styles
import '../../../styles/studio/re-gen.scss';

const Design = ({ design, onMoveToFourthStep }: IDesign) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isProjectSaveProcessing, setIsProjectSaveProcessing] = useState(false);
  const [isProjectSaved, setIsProjectSaved] = useState(false);

  const navigate = useNavigate();

  const onModalOpen = () => {
    setIsModalOpened(true);
  };

  const onModalClose = () => {
    setIsModalOpened(false);
  };

  const onEditInScene = () => {
    navigate({
      pathname: `/tool/scene/${design.digitalGood}/`,
      search: `?redirectedFromStudio=1`,
    });
  };

  const onSaveDesignToProjects = () => {
    const designDataToUpdate = {
      id: design.id,
      folder: null,
    };

    setIsProjectSaveProcessing(true);
    updateProject(designDataToUpdate)
      .then(() => {
        setIsProjectSaved(true);
      })
      .finally(() => {
        setIsProjectSaveProcessing(false);
      });
  };

  return (
    <div className="designs-container--design">
      <img
        src={design.preview}
        alt="design"
        className="designs-container--design--image"
      />
      <div className="designs-container--design--actions-container">
        <button type="button" className="re-gen-btn" onClick={onModalOpen}>
          <img
            className="re-gen-btn--icon"
            src="/static/img/icons/edit-white.svg"
            alt="edit"
          />
          <span className="re-gen-btn--title">Edit in scene</span>
        </button>
        <button
          type="button"
          className={cn('re-gen-btn', {
            disabled: isProjectSaved,
          })}
          onClick={onSaveDesignToProjects}
        >
          {!isProjectSaveProcessing ? (
            <>
              <img
                className="re-gen-btn--icon"
                src="/static/img/icons/download-white.svg"
                alt="download"
              />
              <span className="re-gen-btn--title">
                {isProjectSaved ? 'saved' : 'save to projects'}
              </span>
            </>
          ) : (
            <span className="re-gen-btn--title">loading</span>
          )}
        </button>
        <button
          type="button"
          className="re-gen-btn mt-16"
          onClick={() => onMoveToFourthStep(design)}
        >
          <img
            className="re-gen-btn--icon"
            src="/static/img/icons/stars.svg"
            alt="design assistance"
          />
          <span className="re-gen-btn--title">get design assistance</span>
        </button>
      </div>
      <Modal
        isOpen={isModalOpened}
        ariaHideApp={false}
        onRequestClose={onModalClose}
        className="Modal__Content dark"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <p className="redirect-modal--title">you’re about to leave this page</p>
        <p className="redirect-modal--description">
          You’re about to take this template to SCENE, where you can edit the
          design, the format, and all else.
          <br /> <br />
          You won’t be able to return to this screen, so make sure you save the
          ones you like!
        </p>

        <div className="redirect-modal--btns-container">
          <button
            type="button"
            className="redirect-modal--btns-container--btn stay-btn"
            onClick={onModalClose}
          >
            stay here
          </button>

          <button
            type="button"
            className="redirect-modal--btns-container--btn leave-btn"
            onClick={onEditInScene}
          >
            edit in scene
          </button>
        </div>
      </Modal>
    </div>
  );
};

const ReGenThirdStep = ({
  onMoveToFifthStep,
  onMoveToFourthStep,
  reGen,
}: IReGenThirdStep) => {
  return (
    <div className="re-gen--content">
      <div className="designs-container">
        {reGen &&
          reGen.generatedProjects.map((design) => (
            <Design
              key={design.id}
              design={design}
              onMoveToFourthStep={onMoveToFourthStep}
            />
          ))}
      </div>
      <div className="re-gen-button-container">
        <button
          type="button"
          className="re-gen-button-container--btn"
          onClick={onMoveToFifthStep}
        >
          finish
        </button>
      </div>
    </div>
  );
};

export default ReGenThirdStep;

import React from 'react';

// import Help from './Help';
import Feedback from './Feedback';
// import P2DFeedback from './P2DFeedback';
import QRCode from './QRCode';
import PromptToDesign from './PromptToDesign';

import { BottomActionComponentType } from '../../types/bottom-actions.types';

import '../../styles/bottom-actions/index.scss';

const BottomActions = ({
  // help,
  feedback,
  qrCode,
}: BottomActionComponentType) => (
  <div className="Bottom-actions__container">
    <QRCode {...qrCode} />
    <Feedback {...feedback} />
    <PromptToDesign />
    {/* p2d feedback modal. potentially needed */}
    {/* <P2DFeedback /> */}
    {/* <Help {...help} /> */}
  </div>
);

BottomActions.defaultProps = {
  getPreviewFile: () => {},
};

export default BottomActions;

export const injectCustomInspectorBar = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const canvasContainerEl = $(
    "[class^='UBQ_Editor-module__canvasContainer']",
    shadowRootEl,
  );
  const inspectorBarEl = $(
    "[class^='UBQ_InspectorBar-module__block']",
    shadowRootEl,
  );

  const template = `
        <div
            class="UBQ_InspectorBar-module__block--LJY1E"
            id="customInspectorBar"
        >
            <div class="UBQ_InspectorBar-module__area--LWR2R"></div>
            <div class="UBQ_InspectorBar-module__area--LWR2R"></div>
        </div>
    `;

  if (inspectorBarEl.length) {
    const observer = new MutationObserver(() => {
      $("[class^='UBQ_InspectorBar-module__block']", shadowRootEl).eq(1).hide();
    });

    inspectorBarEl.hide();
    inspectorBarEl.before(template);
    // hide default Inspector bar
    observer.observe(canvasContainerEl[0], { childList: true });
  } else {
    canvasContainerEl.prepend(template);
  }
};

export const injectCustomInspectorBarRightSection = async (btnsArray = []) => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const canvasContainerEl = $('#customInspectorBar', shadowRootEl);
  const rightSectionEl = $(
    "[class^='UBQ_InspectorBar-module__area']:last",
    canvasContainerEl,
  );

  const createBtnEl = ({ name = '', icon = '', onPress = () => {} }) => {
    const el = $(`
      <button
          type="button"
          class="UBQ_Button-module__block--HEFkx UBQ_Button-module__ubq-variant_Plain--UDC0y"
      >
          <span>
          ${icon}
          <span>${name}</span>
          </span>
      </button>
  `);

    el.click(onPress);

    return el;
  };

  const areaGroupEl = $(
    '<span class="UBQ_InspectorBar-module__areaGroup--Xhg5d">',
  ).append(btnsArray.map(createBtnEl));

  rightSectionEl.empty().append(areaGroupEl);
};

export const fixRightSidebarPosition = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const inspectorBarEl = $(
    "[class^='UBQ_InspectorBar-module__block']",
    shadowRootEl,
  );
  const rightSidebarEl = $('#ubq-portal-container_panelRight', shadowRootEl);

  if (!inspectorBarEl.length || !rightSidebarEl.length) return;

  rightSidebarEl.css({
    height: 'calc(100% - 48px)',
    top: '48px',
  });
};

export const modifyFontDropdown = async (
  customRecommendedFonts,
  customInternationalFonts,
) => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const portalContainer = $('#ubq-portal-container_default', shadowRootEl)[0];

  const checkIfCustomFontsAppended = () =>
    !!$('#font-section-recommended', shadowRootEl).length;

  const generateFontCategories = (fontsListElements) => {
    const recommendedFonts = [];

    fontsListElements.each((_, el) => {
      if (
        customRecommendedFonts
          .map((font) => font.fontFamily)
          .includes(el.attributes['data-value']?.value)
      ) {
        recommendedFonts.push(el);
      }
    });

    return recommendedFonts;
  };

  const generateFontSectionLabel = (label = '', id = Date.now()) => {
    const styles = {
      fontSection: `
        padding: 0;
        font-size: 18px;
        color: #fff;
        margin: 6px 0;
      `,
    };

    return $(`
      <div style="${styles.fontSection}" id="font-section-${id}">
        <span>${label}</span>
      </div>
    `);
  };

  const generateCustomFontsHTMLSections = (fontsListElements) => {
    const customFontSections = generateFontSectionLabel(
      'Recommended',
      'recommended',
    );

    generateFontCategories(fontsListElements).map((el) =>
      customFontSections.append(el),
    );

    customFontSections.append(generateFontSectionLabel('Other'));

    return customFontSections;
  };

  const generateInternationalFonts = (fontsListElements) => {
    const recommendedFonts = [];

    fontsListElements.each((_, el) => {
      if (
        customInternationalFonts
          .map((font) => font.fontFamily)
          .includes(el.attributes['data-value']?.value)
      ) {
        recommendedFonts.push(el);
      }
    });

    return recommendedFonts;
  };

  const generateInternationalFontsSection = (fontsList) => {
    fontsList.append(generateFontSectionLabel('International'));
    fontsList.append(generateInternationalFonts(fontsList.find('li')));
  };

  const innerMutationObserverCallback = (innerObserver, fontsList) => {
    if (checkIfCustomFontsAppended()) return;

    if ($('#ubq-headline_label', shadowRootEl).text() === 'Text') {
      fontsList.prepend(generateCustomFontsHTMLSections(fontsList.find('li')));
      generateInternationalFontsSection(fontsList);
      innerObserver.disconnect();
    }
  };

  const mutationObserverCallback = () => {
    const fontsList = $(
      '#ubq-portal-container_default div[class*="UBQ_TypefaceSelect-module"] ul',
      $('#root-shadow')[0].shadowRoot,
    );

    if (fontsList.length) {
      const innerObserver = new MutationObserver(() =>
        innerMutationObserverCallback(innerObserver, fontsList),
      );
      innerObserver.observe(fontsList[0], { attributes: true, subtree: true });
    }
  };

  const observer = new MutationObserver(mutationObserverCallback);

  observer.observe(portalContainer, { childList: true, subtree: true });
};

export const appendCustomButtonToInspectorBar = async (
  buttonLabel,
  buttonClickHandler,
) => {
  await new Promise((resolve) => {
    setTimeout(resolve, 100);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const inspectorBar = $('#ubq-portal-container_panelRight', shadowRootEl)[0];

  const styles = {
    customButton: `
      width: 100%;
      padding: 6px 4px;
    `,
    customSectionBody: `
      display: flex;
      flex-direction: column;
      gap: 10px 0;
    `,
  };

  const createCustomImageButton = () => {
    const modifiedButtonLabel = buttonLabel.toLowerCase().split(' ').join('-');
    const buttonEl = $("[class^='UBQ_Button__block']", inspectorBar).first();

    if (
      $(
        `button[id^='custom-image-button-${modifiedButtonLabel}']`,
        inspectorBar,
      ).length
    )
      return;

    const customButton = $(`
      <button
        class="${buttonEl.attr('class')}"
        style="${styles.customButton}"
        onclick="${buttonClickHandler}"
        id="custom-image-button-${modifiedButtonLabel}"
      >
        ${buttonLabel}
      </button>
    `);

    customButton.on('click', buttonClickHandler);

    return customButton;
  };

  const createCustomImageSection = () => {
    const sectionEl = $(
      "[class^='UBQ_Section-module__block']",
      inspectorBar,
    ).first();
    const sectionHeadingEl = $(
      "[class^='UBQ_Heading-module__heading']",
      sectionEl,
    ).first();
    const sectionHeadingContentEl = $(
      "[class^='UBQ_Section-module__headingTitle']",
      sectionHeadingEl,
    );
    const sectionBodyEl = $(
      "[class^='UBQ_Section-module__content']",
      sectionEl,
    ).first();

    const customSection = $(`
      <section class="${sectionEl.attr('class')}" id="custom-image-section">
        <h3
          class="${sectionHeadingEl
            .attr('class')
            .split(' ')
            .filter((className) => !className.includes('highlight'))
            .join(' ')}
          "
        >
          <span
            class="${sectionHeadingContentEl.attr('class')}"
          >
            Custom Functionality
          </span>
        </h3>
      </section>
    `);

    const customSectionBody = $(`
      <div
        class="${sectionBodyEl.attr('class')}"
        id="custom-image-section-body"
        style="${styles.customSectionBody}"
      >
      </div>
    `);

    $(createCustomImageButton()).appendTo(customSectionBody);
    customSectionBody.appendTo(customSection);

    return customSection;
  };

  const innerMutationObserverCallback = (innerObserver, inspectorBarBody) => {
    const customImageSection = $('#custom-image-section', inspectorBar);
    const customImageSectionBody = $(
      '#custom-image-section-body',
      inspectorBar,
    );

    if (customImageSection.length) {
      return customImageSectionBody.append(createCustomImageButton());
    }

    inspectorBarBody.prepend(createCustomImageSection());
    innerObserver.disconnect();
  };

  const mutationObserverCallback = () => {
    const inspectorBarHeader = $('section', inspectorBar).first();
    const isImageSectionInAdvancedBar = !!$(
      "button[data-cy^='crop']",
      inspectorBar,
    ).length;
    const inspectorBarBody = inspectorBarHeader.siblings('div');

    if (isImageSectionInAdvancedBar) {
      const innerObserver = new MutationObserver(() =>
        innerMutationObserverCallback(innerObserver, inspectorBarBody),
      );

      innerObserver.observe(inspectorBar, {
        attributes: true,
        subtree: true,
        childList: true,
      });
    }
  };

  const observer = new MutationObserver(mutationObserverCallback);

  observer.observe(inspectorBar, {
    childList: true,
    subtree: true,
    attributes: true,
  });
};

export const hideInspectorBarPagesSectionElements = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 1000);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const inspectorBar = $('#ubq-portal-container_panelRight', shadowRootEl);

  const mutationObserverCallback = () => {
    const inspectorBarHeading = $('#ubq-headline_label', inspectorBar);

    if (inspectorBarHeading.text() === 'Document') {
      const pagesFormat = $('label:contains("Format")', inspectorBar).parent();
      const pagesWidth = $('label:contains("Width")', inspectorBar).parent();
      const pagesHeight = $('label:contains("Height")', inspectorBar).parent();
      const pagesUnit = $('label:contains("Unit")', inspectorBar).parent();

      if (pagesFormat.length) {
        pagesFormat.css('display', 'none');
        pagesFormat.next().css('display', 'none');
      }
      if (pagesWidth.length) {
        // might be needed in future
        // pagesWidth.css('display', 'none');
      }
      if (pagesHeight.length) {
        // might be needed in future
        // pagesHeight.css('display', 'none');
        // pagesHeight.next().css('display', 'none');
      }
      if (pagesUnit.length) {
        // might be needed in future
        // pagesUnit.css('display', 'none');
      }
    }
  };

  const observer = new MutationObserver(mutationObserverCallback);
  observer.observe(inspectorBar[0], { childList: true, subtree: true });
};

export const modifyErrorModal = (errorText) => {
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;

  const portalContainer = $('#ubq-portal-container_default', shadowRootEl);

  const mutationObserverCallback = () => {
    if (
      $('h1', portalContainer).length &&
      $('h1', portalContainer).text() === 'Error'
    ) {
      setTimeout(() => {
        $($('h1', portalContainer).next()).text(errorText);
      }, 100);
    }
  };

  const observer = new MutationObserver(mutationObserverCallback);

  observer.observe(shadowRootEl, {
    childList: true,
    subtree: true,
    attributes: true,
  });
};

export const removePlaceholder = () => {
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;

  const mutationObserverCallback = () => {
    const tippy = $('div[id*="tippy"]', shadowRootEl);

    if (tippy.length) {
      $('button[name*="placeholdersettings"]', tippy).hide();
      $('button[name*="placeholdersettings"]', tippy).next().hide();
    }

    $(
      'button[data-cy*="placeholdersettings-create"]',
      $('#ubq-portal-container_panelRight', shadowRootEl),
    ).hide();
  };

  const observer = new MutationObserver(mutationObserverCallback);

  observer.observe(shadowRootEl, {
    childList: true,
    subtree: true,
    attributes: true,
  });
};

export const removeInsertVariablesSection = () => {
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;

  const mutationObserverCallback = () => {
    const tippy = $('div[id*="tippy"]', shadowRootEl);
    const insertVariable = $('button[data-cy*="insert-variable"]', tippy);

    if (tippy.length && insertVariable.length) {
      insertVariable.parent().hide();
    }
  };

  const observer = new MutationObserver(mutationObserverCallback);

  observer.observe(shadowRootEl, {
    childList: true,
    subtree: true,
    attributes: true,
  });
};

export const hideExpandDefaultColorsButton = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 1000);
  });
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const inspectorBar = $('#ubq-portal-container_panelRight', shadowRootEl);

  const mutationObserverCallback = () => {
    const inspectorBarHeading = $('#ubq-headline_label', inspectorBar);
    const innerInspectorBarHeading = $(
      'h2[data-cy*="panel-title"]',
      inspectorBar,
    );

    if (
      inspectorBarHeading.text() === 'Text' &&
      innerInspectorBarHeading.text() === 'TextFill'
    ) {
      const moreButton = $('button[data-cy*="undefined--enter"]', inspectorBar);

      moreButton.hide();
    }
  };

  const observer = new MutationObserver(mutationObserverCallback);
  observer.observe(inspectorBar[0], { childList: true, subtree: true });
};

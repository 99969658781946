import React from 'react';

import '../../styles/unsupported-device-screen.scss';

const UnsupportedDeviceScreen = () => {
  return (
    <div className="unsupported-device-container">
      <p className="unsupported-device-container--text-logo">coolab</p>
      <p className="unsupported-device-container--info">
        Coolab’s web app is only supported on desktop for now.
        <br />
        To access, please switch to a device with a bigger screen.
      </p>
    </div>
  );
};

export default UnsupportedDeviceScreen;

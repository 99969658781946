// api
import { adjustSceneToSelectedPageFormat } from '../../api/assets';

// helpers
import { customAssetSourceId, cesdkBlockTypes } from '../data/canvasConstants';
import { customTextElements } from './customTextElements';

import renderAIText from './customAiText';
import {
  renderRephraseAIText,
  getSelectedTextBlocks,
} from './customRephraseAiText.js';

const selectLayer = (layerId, cesdk) => {
  const firstSelectedBlock = cesdk.engine.block.findAllSelected()[0];
  if (firstSelectedBlock) {
    cesdk.engine.block.setSelected(firstSelectedBlock, false);
  }
  cesdk.engine.block.setSelected(layerId, true);
};

const createLayer = (
  layer,
  cesdk,
  isReplaceable,
  defaultCheckedValues,
  onHandleReplaceableLayers,
) => {
  if (layer.textValue) {
    const textEl = $('<div></div>')
      .text(layer.textValue)
      .attr(
        'class',
        'UBQ_Card-module__block--Zs4rt UBQ_Card-module__autoHeight--yuOcz UBQ_AssetLibraryCard-module__block--z689o UBQ_AssetLibraryCard-module__containsLabel--tsqhH',
      )
      .css({
        width: `${isReplaceable ? '75%' : '90%'}`,
        margin: `${isReplaceable ? '0' : '0 auto'}`,
        height: 'auto',
        border: '1px solid transparent',
      })
      .click(() => {
        selectLayer(layer.id, cesdk);
      });

    if (isReplaceable) {
      return $('<div></div>')
        .css({ display: 'flex', gap: '20px', alignItems: 'center' })
        .append(textEl)
        .append(
          $('<input>')
            .attr({
              type: 'checkbox',
              checked:
                defaultCheckedValues && defaultCheckedValues.includes(layer.id),
            })
            .css({
              width: '18px',
              height: '18px',
            })
            .click(() => {
              onHandleReplaceableLayers(layer.id);
            }),
        );
    }

    return textEl;
  }

  // TODO:Ros - fix later
  if (layer.imageUrl) {
    const imageEl = $('<div></div>')
      .css({
        'background-image': `url(${layer.imageUrl})`,
        height: '150px',
        width: `${isReplaceable ? '75%' : '90%'}`,
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        margin: `${isReplaceable ? '0' : '0 auto'}`,
        border: '1px solid transparent',
      })
      .attr(
        'class',
        'UBQ_Card-module__block--mVR_d UBQ_Card-module__autoHeight--hhfE7 UBQ_AssetLibraryCard-module__block--MuwxC UBQ_AssetLibraryCard-module__hasBackground--CnhDd',
      )
      .click(() => {
        selectLayer(layer.id, cesdk);
      });

    if (isReplaceable) {
      return $('<div></div>')
        .css({ display: 'flex', gap: '20px', alignItems: 'center' })
        .append(imageEl)
        .append(
          $('<input>')
            .attr({
              type: 'checkbox',
              checked:
                defaultCheckedValues && defaultCheckedValues.includes(layer.id),
            })
            .css({
              width: '18px',
              height: '18px',
            })
            .click(() => {
              onHandleReplaceableLayers(layer.id);
            }),
        );
    }

    return imageEl;
  }

  // TODO:Ros - fix later
  if (layer.shapePath) {
    const styles = {
      svg: `
        width: 100%;
        height: 100%;
        fill: black;
        margin-left: 12px;
        margin-top: 4px;
      `,
      path: `
        d: path('${layer.shapePath}');
        stroke: black;
        stroke-opacity: 0.9;
        stroke-width: 1.5;
        stroke-linecap: square;
      `,
    };

    let widthForViewbox = layer.width;
    let heightForViewbox = layer.height;

    if (layer.width < 30) {
      if (layer.height > 30) {
        widthForViewbox = layer.height;
      }

      widthForViewbox = 500;
    }

    if (layer.height < 30) {
      if (layer.width > 30) {
        heightForViewbox = layer.width;
      }

      heightForViewbox = 500;
    }

    const svg = `
        <svg
          style="${styles.svg}"
          viewBox="0 0 ${widthForViewbox} ${heightForViewbox}"
        >
          <path style="${styles.path}"></path>
        </svg>
    `;

    const shapeButtonEl = $('<button></button>')
      .attr({
        type: 'button',
        class:
          'UBQ_Card-module__block--mVR_d UBQ_Card-module__autoHeight--hhfE7 UBQ_AssetLibraryCard-module__block--MuwxC UBQ_AssetLibraryCard-module__hasBackground--CnhDd',
        'data-active': 'false',
        'aria-label': '//ly.img.cesdk.vectorpaths.abstract/frame',
        'data-cy': 'assetLibraryCard-//ly.img.cesdk.vectorpaths.abstract/frame',
        name: 'assetLibraryCard-custom-shapes',
        'aria-describedby': 'UBQ__popover-content-group-81',
      })
      .css({
        width: `${isReplaceable ? '82%' : '96%'}`,
        height: 'auto',
        margin: `${isReplaceable ? '0' : '0 auto'}`,
      })
      .append(svg)
      .click(() => {
        selectLayer(layer.id, cesdk);
      });

    if (isReplaceable) {
      return $('<div></div>')
        .css({ display: 'flex', gap: '20px', alignItems: 'center' })
        .append(shapeButtonEl)
        .append(
          $('<input>')
            .attr({
              type: 'checkbox',
              checked:
                defaultCheckedValues && defaultCheckedValues.includes(layer.id),
            })
            .css({
              width: '18px',
              height: '18px',
            })
            .click(() => {
              onHandleReplaceableLayers(layer.id);
            }),
        );
    }

    return shapeButtonEl;
  }
};

const getPageElementsValue = (elements, cesdk) => {
  const modifiedValues = [];
  let modifiedLayerValue = {};

  elements.forEach((el) => {
    switch (cesdk.engine.block.getKind(el)) {
      // TODO:Ros - fix later
      // case cesdkBlockTypes.image:
      //   modifiedLayerValue = {
      //     id: el,
      //     imageUrl: cesdk.engine.block.getString(el, 'image/imageFileURI'),
      //   };
      //   break;
      // case cesdkBlockTypes.sticker:
      //   modifiedLayerValue = {
      //     id: el,
      //     imageUrl: cesdk.engine.block.getString(el, 'sticker/imageFileURI'),
      //   };
      //   break;
      case cesdkBlockTypes.text:
        modifiedLayerValue = {
          id: el,
          textValue: cesdk.engine.block.getString(el, 'text/text'),
        };
        break;
      // TODO:Ros - fix later
      // case cesdkBlockTypes.shape:
      //   modifiedValues.push({
      //     id: el,
      //     shapePath: cesdk.engine.block.getString(el, 'vector_path/path'),
      //     width: cesdk.engine.block.getFloat(el, 'vector_path/width'),
      //     height: cesdk.engine.block.getFloat(el, 'vector_path/height'),
      //   });
      //   break;
      default:
        break;
    }

    modifiedValues.push(modifiedLayerValue);
  });

  return modifiedValues;
};

const renderLayers = (
  cesdk,
  isReplaceable,
  defaultCheckedValues,
  onHandleReplaceableLayers,
) => {
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const sidebarEl = $("[class^='UBQ_AssetLibraryContent']", shadowRootEl);

  const pages = cesdk.engine.block.findByType('page');
  const pageElements = cesdk.engine.block.getChildren(pages[0]);

  const pageElementsValues = getPageElementsValue(pageElements, cesdk);

  if (!pageElementsValues.length) {
    return;
  }

  // replacing default sidebar markup on custom
  const layersContainerId = 'layers-container';
  const customAssetsContainer = $('<div></div>')
    .attr('id', layersContainerId)
    .css({
      display: 'grid',
      'grid-template-columns': '1fr',
      gap: '15px 0',
    });

  $('[class*=UBQ_MasonryGrid-module__block]', sidebarEl).parent().empty();
  if (!$(`#${layersContainerId}`, sidebarEl).length) {
    pageElementsValues
      .filter((item, index, array) => {
        return index == array.indexOf(item);
      })
      .forEach((element) => {
        customAssetsContainer.prepend(
          createLayer(
            element,
            cesdk,
            isReplaceable,
            defaultCheckedValues,
            onHandleReplaceableLayers,
          ),
        );
      });

    $('[class*=UBQ_AssetLibraryGrid-module__block]', sidebarEl).append(
      customAssetsContainer,
    );
  }
};

const createCustomPageFormat = (format, cesdk, setFormatIntoQuery) => {
  return $('<div></div>')
    .click(async () => {
      setFormatIntoQuery(format.id);
      const savedScene = await cesdk.save();

      const scenePageFormatFD = new FormData();
      scenePageFormatFD.append('scene_bytes', savedScene);
      scenePageFormatFD.append('format', format.id);

      adjustSceneToSelectedPageFormat(scenePageFormatFD).then((resp) => {
        cesdk.loadFromString(resp.scaled_scene);
      });
    })
    .attr(
      'class',
      'UBQ_Card-module__block--Zs4rt UBQ_Card-module__autoHeight--yuOcz UBQ_AssetLibraryCard-module__block--z689o UBQ_AssetLibraryCard-module__containsLabel--tsqhH',
    )
    .css({
      height: '126px',
      width: '126px',
      margin: '0 auto',
      padding: '16px 16px 0 16px',
      'box-sizing': 'border-box',
      background: '#212121',
    })
    .append(
      // container for image with label + bottomLabel
      $('<div></div>')
        .css({ height: '100%', width: '100%' })
        .append(
          // platform image
          $('<div></div>')
            .css({
              'background-image': `url(${format.preview})`,
              'background-size': 'contain',
              'background-repeat': 'no-repeat',
              display: 'flex',
              'align-items': 'center',
              height: '80%',
              color: '#ffffff',
            })
            .append(
              $('<div></div>')
                .css('margin', '0 auto')
                .append(
                  // page format name
                  $('<p></p>')
                    .css({
                      'text-align': 'center',
                      'font-weight': 600,
                      'line-height': '16px',
                      'letter-spacing': '0.02em',
                      margin: '0 0 3px 0',
                    })
                    .text(format.name),
                )
                .append(
                  // page format dimensions
                  $('<p></p>')
                    .css({
                      color: '#ffffff',
                      'text-align': 'center',
                      'font-size': '12px',
                      'line-height': '12px',
                      margin: 0,
                    })
                    .text(
                      `${format.sizePixels.width} x ${format.sizePixels.height}`,
                    ),
                ),
            ),
        )
        .append(
          // platform name
          $('<p></p>')
            .css({
              height: '20%',
              color: '#ffffff',
              display: 'flex',
              'text-align': 'center',
              'align-items': 'center',
              'justify-content': 'center',
              'font-size': '12px',
              'line-height': '12px',
              margin: 0,
            })
            .text(format.group),
        ),
    );
};

const renderCustomPageFormats = (cesdk, pageFormats, setFormatIntoQuery) => {
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const sidebarEl = $("[class^='UBQ_AssetLibraryContent']", shadowRootEl);

  const formatsContainerId = 'formats-container';
  const customFormatsContainer = $('<div></div>')
    .attr('id', formatsContainerId)
    .css({
      display: 'grid',
      'grid-template-columns': '1fr 1fr',
      gap: '12px',
    });
  const pageFormatsInputContainer = $('<div></div>')
    .attr('class', 'UBQ_AssetLibrarySearch-module__inputContainer--0-LjK')
    .css('margin-bottom', '20px');
  const pageFormatsInput = $('<input>').attr({
    class:
      'UBQ_BaseInput-module__block--VEaMq UBQ_AssetLibrarySearch-module__searchInput--GoKQU',
    placeholder: 'Search ...',
    style: 'box-shadow:none;',
  });
  const searchIconsContainer = $('<div></div>').attr(
    'class',
    'UBQ_AssetLibrarySearch-module__searchIconContainer--MQrw6',
  );

  const styles = {
    svg: `
      width: 16px;
      height: 16px;
      fill: white
    `,
    path: `
      d: path('M3 7.5C3 5.01472 5.01472 3 7.5 3C9.98528 3 12 5.01472 12 7.5C12 9.98528 9.98528 12 7.5 12C5.01472 12 3 9.98528 3 7.5ZM7.5 2C4.46243 2 2 4.46243 2 7.5C2 10.5376 4.46243 13 7.5 13C8.74835 13 9.89957 12.5841 10.8226 11.8833L10.9697 12.0303L12.9697 14.0303L13.5 14.5607L14.5607 13.5L14.0303 12.9697L12.0303 10.9697L11.8833 10.8226C12.5841 9.89957 13 8.74835 13 7.5C13 4.46243 10.5376 2 7.5 2Z');
      stroke: none;
      stroke-opacity: 0.9;
      stroke-width: 1.5;
      stroke-linecap: square;
    `,
  };
  const searchIcon = `<svg
    style="${styles.svg}"
  >
    <path style="${styles.path}"></path>
  </svg>`;

  searchIconsContainer.append(searchIcon);
  pageFormatsInputContainer.append(pageFormatsInput);
  pageFormatsInputContainer.append(searchIconsContainer);

  let keyupTimeoutID = 0;

  $(pageFormatsInput).on('input', (e) => {
    clearTimeout(keyupTimeoutID);
    keyupTimeoutID = setTimeout(() => {
      $(`#${formatsContainerId}`, sidebarEl).empty();
      const searchTerms = e.target.value.toLowerCase().split(/\s+/);

      pageFormats
        .filter((format) =>
          searchTerms.every(
            (term) =>
              format.name.toLowerCase().includes(term) ||
              format.group.toLowerCase().includes(term),
          ),
        )
        .forEach((format) => {
          customFormatsContainer.append(
            createCustomPageFormat(format, cesdk, setFormatIntoQuery),
          );
        });
    }, 100);
  });

  $('[class*=UBQ_MasonryGrid-module__block]', sidebarEl).parent().empty();
  if (!$(`#${formatsContainerId}`, sidebarEl).length) {
    pageFormats.forEach((format) => {
      customFormatsContainer.append(
        createCustomPageFormat(format, cesdk, setFormatIntoQuery),
      );
    });
    $('[class*=UBQ_AssetLibraryGrid-module__block]', sidebarEl).append(
      pageFormatsInputContainer,
    );
    $('[class*=UBQ_AssetLibraryGrid-module__block]', sidebarEl).append(
      customFormatsContainer,
    );
  }
};

const modifySidebarHeading = (label) => {
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const inspectorHeading = $(
    '#ubq-portal-container_panelLeft h2[data-cy*=panel-title]',
    shadowRootEl,
  );
  inspectorHeading.text(label);
};

const getLibraryDockEl = () => {
  return $("[data-cy='asset-library-dock']", $('#root-shadow')[0].shadowRoot);
};

const modifySidebarContentElement = () => {
  $("[class^='UBQ_Card-module__block']", $('#root-shadow')[0].shadowRoot).each(
    (_, el) => {
      $(el).css('border', '1px solid transparent');
      $(el).hover(
        () => {
          $(el).css('border', '1px solid #727272');
          $(el).css({
            'box-shadow': 'none',
            '--ubq-interactive-hover':
              'linear-gradient(180deg, #727272 0%, #484848 100%)',
          });
        },
        () => {
          $(el).css('border', '1px solid transparent');
        },
      );
    },
  );
};

const onCustomTextElClick = (cesdk, textEl) => {
  const customTextEl = cesdk.engine.block.create('text');
  const pageEl = cesdk.engine.block.findByType('page')[0];

  cesdk.engine.block.setFloat(customTextEl, 'height', 150);
  cesdk.engine.block.setFloat(customTextEl, 'width', 500);

  cesdk.engine.block.appendChild(pageEl, customTextEl);

  const textProperties = Object.keys(textEl).filter(
    (property) =>
      property.includes('text') &&
      !property !== 'textColor' &&
      !property.includes('Alignment'),
  );

  cesdk.engine.block.setEnum(
    customTextEl,
    'text/horizontalAlignment',
    textEl['text/horizontalAlignment'],
  );
  cesdk.engine.block.setEnum(
    customTextEl,
    'text/verticalAlignment',
    textEl['text/verticalAlignment'],
  );

  textProperties.forEach((property) => {
    if (typeof textEl[property] === 'string') {
      cesdk.engine.block.setString(customTextEl, property, textEl[property]);
    } else if (typeof textEl[property] === 'number') {
      cesdk.engine.block.setFloat(customTextEl, property, textEl[property]);
    } else if (typeof textEl[property] === 'boolean') {
      cesdk.engine.block.setBool(customTextEl, property, textEl[property]);
    }
  });

  cesdk.engine.block.setBool(customTextEl, 'clipped', textEl.clipped);
  cesdk.engine.block.setBool(
    customTextEl,
    'dropShadow/clip',
    textEl['dropShadow/clip'],
  );
  cesdk.engine.block.setBool(
    customTextEl,
    'text/clipLinesOutsideOfFrame',
    textEl['text/clipLinesOutsideOfFrame'],
  );

  // dropShadow
  if (textEl['dropShadow/enabled']) {
    cesdk.engine.block.setDropShadowEnabled(customTextEl, true);
    cesdk.engine.block.setDropShadowColor(customTextEl, textEl.dropShadowColor);
    cesdk.engine.block.setDropShadowOffsetX(
      customTextEl,
      textEl.dropShadowOffsetX,
    );
    cesdk.engine.block.setDropShadowOffsetY(
      customTextEl,
      textEl.dropShadowOffsetY,
    );
    cesdk.engine.block.setDropShadowClip(customTextEl, textEl.dropShadowClip);
    cesdk.engine.block.setDropShadowBlurRadiusX(
      customTextEl,
      textEl.dropShadowBlurRadiusX,
    );
    cesdk.engine.block.setDropShadowBlurRadiusY(
      customTextEl,
      textEl.dropShadowBlurRadiusY,
    );
  }

  // fill
  if (textEl.isFillEnabled) {
    cesdk.engine.block.setTextColor(customTextEl, textEl.textColor);
  } else {
    cesdk.engine.block.setFillEnabled(customTextEl, false);
  }

  // stroke
  if (textEl.strokeEnabled) {
    cesdk.engine.block.setStrokeEnabled(customTextEl, true);
    cesdk.engine.block.setStrokeWidth(customTextEl, textEl.strokeWidth);
    cesdk.engine.block.setStrokeColor(customTextEl, textEl.strokeColor);
    cesdk.engine.block.setStrokeStyle(customTextEl, textEl.strokeStyle);
    cesdk.engine.block.setStrokePosition(customTextEl, textEl.strokePosition);
    cesdk.engine.block.setStrokeCornerGeometry(
      customTextEl,
      textEl.strokeCornerGeometry,
    );
  }
};

const injectCustomText = (cesdk, textEl) => {
  const customTextBtn = $('<div></div>')
    .css({
      'background-image': `url("${textEl.image}")`,
      height: '82px',
      width: '67px',
      'background-size': 'contain',
      'background-repeat': 'no-repeat',
      margin: '0 auto',
      border: '1px solid transparent',
    })
    .attr(
      'class',
      'UBQ_Card-module__block--Zs4rt UBQ_Card-module__autoHeight--yuOcz UBQ_AssetLibraryCard-module__block--z689o UBQ_AssetLibraryCard-module__containsLabel--tsqhH',
    )
    .click(() => onCustomTextElClick(cesdk, textEl));

  return customTextBtn;
};

const injectImageLinkBtn = (onQRCodeModalOpen) => {
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;
  const enterImageLinkBtn = $(`
  <button
      type="button"
      class="${$('[class^="UBQ_Button__block"]', shadowRootEl).attr('class')}"
      id="qr-code-modal-trigger"
      style="height:40px; width:264px;background-color: #727272;border: none;color: #ffffff;cursor: pointer;margin-top: 16px"
  >
      <span>
          <span style="text-align:center;">Enter Image Link</span>
      </span>
  </button>
`).on('click', onQRCodeModalOpen);

  return enterImageLinkBtn;
};

const injectCustomTextElements = (cesdk, onQRCodeModalOpen) => {
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;

  if (!$('#qr-code-modal-trigger', shadowRootEl).length) {
    $("[class^='UBQ_MasonryGrid']", shadowRootEl).attr(
      'style',
      'grid-auto-rows: auto; grid-template-columns: repeat(3, 82.6667px);',
    );
    customTextElements.forEach((textEl) => {
      $($("[class^='UBQ_MasonryGrid']", shadowRootEl)[0]).append(
        injectCustomText(cesdk, textEl),
      );
    });

    $($("[class^='UBQ_MasonryGrid']", shadowRootEl)[0]).append(
      $('<div></div>'),
    );
    $($("[class^='UBQ_MasonryGrid']", shadowRootEl)[0]).append(
      $('<div></div>'),
    );

    $($("[class^='UBQ_MasonryGrid']", shadowRootEl)[0]).append(
      injectImageLinkBtn(onQRCodeModalOpen),
    );
  }
};

export default ({
  onTemplateChange = () => {},
  getUploadImagesCount = () => null,
  cesdk,
  pageFormats,
  setFormatIntoQuery,
  onQRCodeModalOpen,
  userProfile,
  onHandleReplaceableLayers,
}) => {
  // custom sidebar logic
  let listeners = [];
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;

  const checkIfAssetLibraryGridExist = () =>
    !!$("[class^='UBQ_AssetLibraryGrid-module__block']", shadowRootEl).length;
  const renderCustomElement = (type) => {
    const sidebarEl = $("[class^='UBQ_AssetLibraryContent']", shadowRootEl);
    let modifySidebarFunc = () => {};
    const disableUploadBtn = async () => {
      const uploadBtn = $('button#Upload', sidebarEl);

      // Do not allow user to upload more than x images
      if (
        userProfile?.maxUploadedImages &&
        (await getUploadImagesCount()) >= userProfile?.maxUploadedImages
      ) {
        uploadBtn.attr('disabled', true);
      }
    };

    const updateTemplateCustomFunc = () => {
      const portalContainer = $('#ubq-portal-container_default', shadowRootEl);
      let triggeredTemplateId = null;
      // manually update scene on dialog confirm
      const onPortalUpdate = () => {
        $(
          "button[name='template-preset-dialog-button-confirm']",
          portalContainer,
        )
          .off('click')
          .click(() => onTemplateChange(triggeredTemplateId));
      };
      const portalObserver = new MutationObserver(onPortalUpdate);

      // get and set scene on thuml click in sidebar
      $(`[name='assetLibraryCard-${customAssetSourceId.templates}']`, sidebarEl)
        .off('click')
        .click(({ target }) => {
          triggeredTemplateId = $(target)
            .attr('data-cy')
            .replace(/[^0-9]/g, '');
        });

      // listen portal updates, where confirm dialog is located
      portalObserver.observe(portalContainer[0], {
        childList: true,
      });
      listeners.push(portalObserver);
    };

    const searchBar = $(
      "[class*='UBQ_AssetLibrarySearch-module__block']",
      shadowRootEl,
    );
    const hideSearch = () => {
      searchBar.hide();
    };
    const showSearch = () => {
      $('input', searchBar).css('box-shadow', 'none');
      searchBar.show();
    };

    listeners.forEach((listener) => listener.disconnect());
    listeners = [];

    switch (type) {
      case `librarydock-${customAssetSourceId.templates}`:
        {
          modifySidebarFunc = () => {
            showSearch();
            updateTemplateCustomFunc();
            modifySidebarHeading('Templates');
            modifySidebarContentElement();
          };
        }
        break;
      case `librarydock-${customAssetSourceId.uploads}`:
        {
          modifySidebarFunc = () => {
            showSearch();
            disableUploadBtn();
            modifySidebarHeading('Uploads');
            modifySidebarContentElement();
          };
        }
        break;
      case 'librarydockGroupOverview-ly.img.defaultGroup':
        {
          modifySidebarFunc = () => {
            showSearch();
            disableUploadBtn();
            updateTemplateCustomFunc();
            modifySidebarHeading('Elements');
            modifySidebarContentElement();
          };
        }
        break;
      case `librarydock-${customAssetSourceId.images}`:
        {
          modifySidebarFunc = () => {
            showSearch();
            modifySidebarHeading('Images');
            modifySidebarContentElement();
          };
        }
        break;
      case `librarydock-${customAssetSourceId.text}`:
        {
          modifySidebarFunc = () => {
            showSearch();
            modifySidebarHeading('Text');
            modifySidebarContentElement();
            injectCustomTextElements(cesdk, onQRCodeModalOpen);
          };
        }
        break;
      case `librarydock-${customAssetSourceId.shapes}`:
        {
          modifySidebarFunc = () => {
            showSearch();
            modifySidebarHeading('Shapes');
            modifySidebarContentElement();
          };
        }
        break;
      case `librarydock-${customAssetSourceId.stickers}`:
        {
          modifySidebarFunc = () => {
            showSearch();
            modifySidebarHeading('Stickers');
            modifySidebarContentElement();
          };
        }
        break;
      case `librarydock-${customAssetSourceId.layers}`:
        {
          modifySidebarFunc = () => {
            hideSearch();
            renderLayers(cesdk);
            modifySidebarHeading('Layers');
            modifySidebarContentElement();
          };
        }
        break;
      case `librarydock-${customAssetSourceId.replaceable}`:
        {
          const urlParams = new URLSearchParams(window.location.search);

          modifySidebarFunc = () => {
            hideSearch();
            renderLayers(
              cesdk,
              true,
              urlParams.get('replaceable'),
              onHandleReplaceableLayers,
            );
            modifySidebarHeading('Replaceable');
            modifySidebarContentElement();
          };
        }
        break;
      case `librarydock-${customAssetSourceId.formats}`:
        {
          modifySidebarFunc = () => {
            hideSearch();
            renderCustomPageFormats(cesdk, pageFormats, setFormatIntoQuery);
            modifySidebarHeading('Formats');
            modifySidebarContentElement();
          };
        }
        break;
      case `librarydock-${customAssetSourceId.pexels}`:
        {
          modifySidebarFunc = () => {
            showSearch();
            modifySidebarHeading('Photos');
            modifySidebarContentElement();
          };
        }
        break;
      case `librarydock-${customAssetSourceId.ai_text}`:
        {
          modifySidebarFunc = () => {
            const observer = new MutationObserver(() => {
              const inspectorHeading = $(
                '#ubq-portal-container_panelLeft h2[data-cy*=panel-title]',
                shadowRootEl,
              );

              if (
                inspectorHeading.text().includes('A.I.') ||
                inspectorHeading.text() === 'Rephrase'
              ) {
                const { textSelected } = getSelectedTextBlocks(cesdk);

                if (textSelected) {
                  modifySidebarHeading('Rephrase');
                  $('#ai-text-container', shadowRootEl).hide();
                  $('#ai-text-container-rephrase', shadowRootEl).show();

                  hideSearch();
                  renderRephraseAIText(cesdk);
                } else {
                  modifySidebarHeading('Write your text with A.I.');

                  $('#ai-text-container-rephrase', shadowRootEl).hide();
                  $('#ai-text-container', shadowRootEl).show();

                  hideSearch();
                  renderAIText(cesdk, userProfile.credits);
                }
              }
            });

            observer.observe(shadowRootEl, {
              attributes: true,
              subtree: true,
            });
          };
        }
        break;
      default:
        {
          modifySidebarFunc = () => {
            showSearch();
            disableUploadBtn();
            updateTemplateCustomFunc();
            modifySidebarHeading('Library');
          };
        }
        break;
    }

    const observer = new MutationObserver(modifySidebarFunc);

    observer.observe(sidebarEl[0], {
      childList: true,
      subtree: true,
    });
    listeners.push(observer);
  };

  const observer = new MutationObserver(() => {
    const activeCategory = getLibraryDockEl()
      .find('button')
      .filter((i, el) =>
        $(el).attr('class').includes('UBQ_Button-module__ubq-state_selected'),
      )
      .attr('name');

    if (checkIfAssetLibraryGridExist()) {
      renderCustomElement(activeCategory);
    } else {
      const sidebarEl = $('#ubq-portal-container_panelLeft', shadowRootEl);

      const observer2 = new MutationObserver(() => {
        renderCustomElement(activeCategory);
        observer2.disconnect();
      });

      observer2.observe(sidebarEl[0], {
        attributes: true,
        subtree: true,
      });
    }
  });

  const observer3 = new MutationObserver(() => {
    if (getLibraryDockEl().length) {
      observer.observe($("[data-cy='asset-library-dock']", shadowRootEl)[0], {
        attributes: true,
        subtree: true,
      });
    }
  });

  observer3.observe(shadowRootEl, {
    attributes: true,
    subtree: true,
  });
};

// might be used later
// export const autoOpenTemplatesDock = () => {
//   const shadowRootEl = $('#root-shadow')[0].shadowRoot;
//   const libraryDockEl = $("[data-cy='asset-library-dock']", shadowRootEl);

//   $("[data-cy='librarydock-custom-templates']", libraryDockEl).click();
// };

export const applyHorizontalGapForTemplatesElement = async () => {
  const shadowRootEl = $('#root-shadow')[0].shadowRoot;

  const observer = new MutationObserver(() => {
    const templatesElement = $(
      "[data-cy='librarydock-custom-templates']",
      shadowRootEl,
    );

    if (templatesElement.length) {
      templatesElement.css('margin-bottom', '32px');
    }
  });

  observer.observe(shadowRootEl, {
    attributes: true,
    subtree: true,
  });
};

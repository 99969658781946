import fetch from '../helpers/customFetch';

export const createProject = (data, options = {}) => {
  try {
    return fetch({
      url: '/projects/',
      method: 'POST',
      data,
      ...options,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createShareProjectHash = (data) => {
  try {
    return fetch({
      url: '/projects/share/encode/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createCopyProjectByHash = (data) => {
  try {
    return fetch({
      url: '/projects/share/copy/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getProjects = (folderId) => {
  const queryParams = new URLSearchParams();

  if (folderId) {
    queryParams.set('folder', folderId);
  }

  try {
    return fetch({
      url: `/projects/?${queryParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateProject = (project) => {
  try {
    return fetch({
      url: `/projects/${project.id}/`,
      method: 'PATCH',
      data: project,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const deleteProject = (id) => {
  try {
    return fetch({
      url: `/projects/${id}/`,
      method: 'DELETE',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const duplicateProject = (id) => {
  try {
    return fetch({
      url: `/projects/${id}/duplicate/`,
      method: 'POST',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateProjectsOrder = (projects) => {
  try {
    return fetch({
      url: '/projects/update_order/',
      method: 'PATCH',
      data: projects,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateFoldersOrder = (folders) => {
  try {
    return fetch({
      url: '/project-folders/update_order/',
      method: 'PATCH',
      data: folders,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getProjectsFolders = () => {
  try {
    return fetch({
      url: '/project-folders/',
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getProjectsSingleFolder = (id) => {
  try {
    return fetch({
      url: `/project-folders/${id}/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createProjectFolder = (newFolder) => {
  try {
    return fetch({
      url: '/project-folders/',
      method: 'POST',
      data: newFolder,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateProjectFolder = (projectFolder) => {
  try {
    return fetch({
      url: `/project-folders/${projectFolder.id}/`,
      method: 'PATCH',
      data: projectFolder,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const duplicateProjectFolder = (id) => {
  try {
    return fetch({
      url: `/project-folders/${id}/duplicate/`,
      method: 'POST',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const deleteProjectFolder = (id) => {
  try {
    return fetch({
      url: `/project-folders/${id}/`,
      method: 'DELETE',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';

// components
import ProjectCard from '../Projects/ProjectCard';
import ProjectsFolder from '../Projects/ProjectsFolder';
import SingleFolder from './SingleFolder';
import GeneralProjectsContextMenu from './GeneralProjectsContextMenu';

// helpers
import { getToasterOptions } from '../../../helpers/toaster';

// hooks
import useClickOutside from '../../../hooks/useClickOutside';

// api
import {
  getProjects,
  getProjectsFolders,
  createProjectFolder,
  updateProject,
  duplicateProject,
  updateProjectFolder,
  duplicateProjectFolder,
  deleteProjectFolder,
  createProject,
  getProjectsSingleFolder,
  updateFoldersOrder,
} from '../../../api/projects';

// types
import {
  IStudioProject,
  ProjectsFolderType,
} from '../../../types/studio.types';

// styles
import '../../../styles/studio/studio-content.scss';

const ProjectsContent = () => {
  const [projects, setProjects] = useState<IStudioProject[]>([]);
  const [projectFolders, setProjectFolders] = useState<
    ProjectsFolderType[] | undefined
  >();
  const [isFolderView, setIsFolderView] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<
    ProjectsFolderType | undefined
  >();
  const [isProjectDragging, setIsProjectDragging] = useState(false);
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [contextMenuXYPosition, setContextMenuXYPosition] = useState({
    x: 0,
    y: 0,
  });
  const [isFolderDragging, setIsFolderDragging] = useState(false);
  const [shouldUpdateFoldersOrder, setShouldUpdateFoldersOrder] =
    useState(false);
  const [isProjectDuplicateLoading, setIsProjectDuplicateLoading] =
    useState(false);
  const [isFolderDuplicateLoading, setIsFolderDuplicateLoading] =
    useState(false);
  const [rightClickedProject, setRightClickedProject] = useState<null | number>(
    null,
  );
  const [rightClickedFolder, setRightClickedFolder] = useState<null | number>(
    null,
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const folderId = searchParams.get('folderId');

  const projectsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getProjects()
      .then((projectsData) => {
        setProjects(projectsData.results);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with projects',
          getToasterOptions(),
        );
      });
  }, []);

  useEffect(() => {
    getProjectsFolders()
      .then((projectsFolders) => {
        setProjectFolders(projectsFolders.results);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with folders',
          getToasterOptions(),
        );
      });
  }, []);

  useEffect(() => {
    if (folderId) {
      getProjectsSingleFolder(+folderId).then((folder) => {
        setSelectedFolder(folder);
        setIsFolderView(true);
      });
    }
  }, [folderId]);

  useEffect(() => {
    if (shouldUpdateFoldersOrder) {
      const projectFoldersWithOrder = projectFolders?.map(
        (innerProjectFolder, innerIndex) => ({
          id: innerProjectFolder.id,
          order: innerIndex,
        }),
      );

      updateFoldersOrder(projectFoldersWithOrder)
        .catch(() => {
          toast.error(
            'Ooooops, error occurred with keeping projects order',
            getToasterOptions(),
          );
        })
        .finally(() => {
          setShouldUpdateFoldersOrder(false);
        });
    }
  }, [shouldUpdateFoldersOrder, projectFolders]);

  const handleAddNewFolder = (closeContextMenu = false) => {
    createProjectFolder({
      name: 'New Folder',
      order: projectFolders?.length
        ? (projectFolders[projectFolders.length - 1].order as number) + 1
        : 0,
    })
      .then((newFolder: ProjectsFolderType) => {
        setProjectFolders((prevState) =>
          prevState?.length ? [...prevState, newFolder] : [newFolder],
        );
        if (closeContextMenu) {
          setIsContextMenuVisible(false);
        }
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with creating new folder',
          getToasterOptions(),
        );
      });
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setProjects((prevProjects) =>
      update(prevProjects, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevProjects[dragIndex]],
        ],
      }),
    );
  }, []);

  const onDeleteProject = (id: number) => {
    setProjects((prevState) =>
      prevState.filter((project) => project.id !== id),
    );
  };

  const onProjectDuplicate = (id: number) => {
    setIsProjectDuplicateLoading(true);

    duplicateProject(id)
      .then((data) => {
        setProjects((prevState) => [...prevState, data]);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with project duplication',
          getToasterOptions(),
        );
      })
      .finally(() => {
        setIsProjectDuplicateLoading(false);
      });
  };

  const onProjectRename = (updatedProject: IStudioProject) => {
    setProjects((prevState) =>
      prevState.map((project) => {
        if (updatedProject.id === project.id) {
          return updatedProject;
        }

        return project;
      }),
    );
  };

  const checkIsProjectCardDragging = (isDragging: boolean) => {
    setIsProjectDragging(isDragging);
  };

  const checkIsFolderDragging = (isDragging: boolean) => {
    setIsFolderDragging(isDragging);
  };

  const onSelectRightClickedProject = (id: number | null) => {
    setRightClickedProject(id);
  };

  const onSelectRightClickedFolder = (id: number | null) => {
    setRightClickedFolder(id);
  };

  const renderCard = useCallback(
    (project: IStudioProject, index: number) => {
      return (
        <ProjectCard
          key={project.id}
          index={index}
          id={project.id}
          project={project}
          moveCard={moveCard}
          projects={projects}
          onDeleteProject={onDeleteProject}
          onProjectDuplicate={onProjectDuplicate}
          onProjectRename={onProjectRename}
          checkIsProjectCardDragging={checkIsProjectCardDragging}
          isProjectDuplicateLoading={isProjectDuplicateLoading}
          rightClickedProject={rightClickedProject}
          onSelectRightClickedProject={onSelectRightClickedProject}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projects,
      isProjectDragging,
      isProjectDuplicateLoading,
      rightClickedProject,
    ],
  );

  const moveFolder = useCallback((dragIndex: number, hoverIndex: number) => {
    setProjectFolders(
      (prevProjectsFolders) =>
        prevProjectsFolders &&
        update(prevProjectsFolders, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevProjectsFolders[dragIndex]],
          ],
        }),
    );
    setShouldUpdateFoldersOrder(true);
  }, []);

  const onProjectMoveToFolder = (id: number, projectFolderId: number) => {
    updateProject({ id, folder: projectFolderId })
      .then(() => {
        setProjects((prevState) =>
          prevState.filter((project) => project.id !== id),
        );
        setProjectFolders((prevState) =>
          prevState?.map((projectFolder) => {
            if (projectFolder.id === projectFolderId) {
              return {
                ...projectFolder,
                projectTotal: projectFolder?.projectTotal
                  ? projectFolder?.projectTotal + 1
                  : 1,
              };
            }

            return projectFolder;
          }),
        );
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with updating project',
          getToasterOptions(),
        );
      });
  };

  const onFolderOpen = (
    event: React.MouseEvent<HTMLDivElement>,
    projectFolder: ProjectsFolderType,
  ) => {
    if (event.detail == 2 || window.innerWidth <= 480) {
      setIsFolderView((prevState) => !prevState);
      setSelectedFolder(projectFolder);
    }
  };

  const onFolderClose = () => {
    setIsFolderView((prevState) => !prevState);
    setSearchParams('');
  };

  const onProjectMoveToHome = (id: number, projectFolderId: number) => {
    updateProject({ id, folder: null })
      .then((newProject) => {
        setProjects((prevState) => [...prevState, newProject]);
        setProjectFolders((prevState) =>
          prevState?.map((projectFolder) => {
            if (projectFolder.id === projectFolderId) {
              return {
                ...projectFolder,
                projectTotal: projectFolder?.projectTotal
                  ? projectFolder?.projectTotal - 1
                  : 0,
              };
            }

            return projectFolder;
          }),
        );
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with updating project',
          getToasterOptions(),
        );
      });
  };

  const onFolderRename = (projectFolder: ProjectsFolderType) => {
    updateProjectFolder(projectFolder)
      .then((updatedFolder) => {
        setProjectFolders((prevState) =>
          prevState?.map((project) => {
            if (updatedFolder.id === project.id) {
              return updatedFolder;
            }

            return project;
          }),
        );
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with updating folder',
          getToasterOptions(),
        );
      });
  };

  const onProjectFolderDuplicate = (id: number) => {
    setIsFolderDuplicateLoading(true);
    duplicateProjectFolder(id)
      .then((duplicatedProjectFolder: ProjectsFolderType) => {
        setProjectFolders((prevState) =>
          prevState
            ? [...prevState, duplicatedProjectFolder]
            : [duplicatedProjectFolder],
        );
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with updating folder',
          getToasterOptions(),
        );
      })
      .finally(() => {
        setIsFolderDuplicateLoading(false);
      });
  };

  const onProjectFolderDelete = (id: number) => {
    deleteProjectFolder(id)
      .then(() => {
        setProjectFolders((prevState) =>
          prevState?.filter((project) => project.id !== id),
        );
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with deleting folder',
          getToasterOptions(),
        );
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRightMouseClick = (event: any) => {
    if (
      !(
        event.target.className.includes('studio-projects-folders--folder') ||
        event.target.className.includes('studio-project-item')
      )
    ) {
      event.preventDefault();
      setIsContextMenuVisible(false);

      const positionChange = {
        x: event.pageX,
        y: event.pageY,
      };

      setContextMenuXYPosition(positionChange);
      setIsContextMenuVisible(true);
    }
  };

  const onCreateBlankProject = (shouldCloseContextMenu = false) => {
    createProject()
      .then((newProject) => {
        setProjects((prevState) => [...prevState, newProject]);

        if (shouldCloseContextMenu) {
          setIsContextMenuVisible(false);
        }
      })
      .catch((error) => {
        toast.error(
          error?.detail || 'Ooooops, error occurred while creating new project',
          getToasterOptions(),
        );
      });
  };

  const renderFolder = useCallback(
    (projectFolder: ProjectsFolderType, index: number) => {
      return (
        <ProjectsFolder
          key={projectFolder.id}
          projectFolder={projectFolder}
          id={projectFolder.id}
          onProjectMoveToFolder={onProjectMoveToFolder}
          onFolderOpen={onFolderOpen}
          isProjectDragging={isProjectDragging}
          onFolderRename={onFolderRename}
          onProjectFolderDuplicate={onProjectFolderDuplicate}
          onProjectFolderDelete={onProjectFolderDelete}
          index={index}
          moveFolder={moveFolder}
          checkIsFolderDragging={checkIsFolderDragging}
          isFolderDuplicateLoading={isFolderDuplicateLoading}
          rightClickedFolder={rightClickedFolder}
          onSelectRightClickedFolder={onSelectRightClickedFolder}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectFolders,
      isFolderDragging,
      isFolderDuplicateLoading,
      rightClickedFolder,
    ],
  );

  useClickOutside(
    projectsContainerRef,
    () => {
      setIsContextMenuVisible(false);
    },
    'general-context-menu',
  );

  if (isFolderView) {
    return (
      <DndProvider backend={HTML5Backend}>
        <SingleFolder
          onBreadcrumbClick={onFolderClose}
          selectedFolder={selectedFolder}
          onProjectMoveToHome={onProjectMoveToHome}
          onFolderRename={onFolderRename}
        />
      </DndProvider>
    );
  }

  return (
    <div
      onContextMenu={handleRightMouseClick}
      ref={projectsContainerRef}
      className="projects-content"
    >
      <GeneralProjectsContextMenu
        position={contextMenuXYPosition}
        isVisible={isContextMenuVisible}
        handleAddNewFolder={handleAddNewFolder}
        onCreateBlankProject={onCreateBlankProject}
      />
      <DndProvider backend={HTML5Backend}>
        <span
          className="studio-content--label"
          onClick={() => handleAddNewFolder(false)}
        >
          <span>folders </span>
          <img
            src="/static/img/icons/white-bold-plus.svg"
            alt="add design"
            className="studio-content--plus-sign"
          />
        </span>
        <div className="studio-projects-folders">
          {projectFolders?.map((projectFolder, index) =>
            renderFolder(projectFolder, index),
          )}
        </div>
        <span
          className="studio-content--label"
          onClick={() => onCreateBlankProject(false)}
        >
          <span>projects </span>
          <img
            src="/static/img/icons/white-bold-plus.svg"
            alt="add design"
            className="studio-content--plus-sign"
          />
        </span>
        <div className="studio-content projects">
          {projects.map((project, index) => renderCard(project, index))}
        </div>
      </DndProvider>
    </div>
  );
};

export default ProjectsContent;

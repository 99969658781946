import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { store } from './store';

import CanvasEditor from './components/CanvasEditor';
import SandboxEditor from './components/Sandbox';
import StudioEditor from './components/StudioEditor';
import ShareHandler from './components/ShareHandler';

import './styles/general.scss';

function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/tool/scene/">
              <Route index element={<CanvasEditor />} />
              <Route path=":id" element={<CanvasEditor />} />
            </Route>
            <Route path="/tool/studio/" element={<StudioEditor />} />
            <Route path="/tool/sandbox/" element={<SandboxEditor />} />
            <Route path="/tool/scene/share/:hash" element={<ShareHandler />} />
          </Routes>
        </Router>
      </Provider>
      <ToastContainer />
    </>
  );
}

export default App;

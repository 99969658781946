import React from 'react';
import cn from 'classnames';

// types
import { IProjectContextMenu } from '../../../types/studio.types';

// styles
import '../../../styles/studio/studio-content.scss';

const ProjectContextMenu = ({
  isShown,
  position,
  onProjectEdit,
  openProjectDeleteModal,
  onProjectDuplicate,
  openProjectRenameModal,
  onProjectExport,
  isProjectDuplicateLoading,
}: IProjectContextMenu) => {
  if (!isShown) {
    return null;
  }

  return (
    <div style={{ top: position.y, left: position.x }} className="context-menu">
      <button
        className="context-menu--option"
        onClick={onProjectEdit}
        id="context-menu-edit"
        type="button"
      >
        <img src="/static/img/icons/edit-white.svg" alt="edit" />
        <span className="context-menu--option item-text">Edit</span>
      </button>
      <button
        className="context-menu--option"
        id="context-menu-rename"
        onClick={openProjectRenameModal}
        type="button"
      >
        <img src="/static/img/icons/rename.svg" alt="rename" />
        <span className="context-menu--option item-text">Rename</span>
      </button>
      <button
        className={cn('context-menu--option', {
          disabled: isProjectDuplicateLoading,
        })}
        id="context-menu-duplicate"
        onClick={onProjectDuplicate}
        type="button"
      >
        <img src="/static/img/icons/copy-white.svg" alt="duplicate" />
        <span className="context-menu--option item-text">
          {isProjectDuplicateLoading ? 'Loading ...' : 'Duplicate'}
        </span>
      </button>
      <button
        className="context-menu--option"
        id="context-menu-delete"
        onClick={openProjectDeleteModal}
        type="button"
      >
        <img src="/static/img/icons/delete-white.svg" alt="delete" />
        <span className="context-menu--option item-text">Delete</span>
      </button>
      <div className="context-menu--border" />
      <button
        className="context-menu--option"
        id="context-menu-generative-brief"
        onClick={onProjectExport}
        type="button"
      >
        <img src="/static/img/icons/editor/export.svg" alt="export" />
        <span className="context-menu--option item-text">Export</span>
      </button>
      <button
        className="context-menu--option"
        id="context-menu-regen"
        type="button"
      >
        <img
          src="/static/img/icons/editor/studio-generative-brief.svg"
          alt="ai"
        />
        <span className="context-menu--option item-text">re-gen</span>
      </button>
      <button
        className="context-menu--option"
        id="context-menu-format-extender"
        type="button"
      >
        <img
          src="/static/img/icons/editor/studio-generative-brief.svg"
          alt="ai"
        />
        <span className="context-menu--option item-text">format extender</span>
      </button>
    </div>
  );
};

export default ProjectContextMenu;

import React, { useRef, useState, useEffect } from 'react';
import type { Identifier } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import cn from 'classnames';

// components
import ProjectContextMenu from './ProjectContextMenu';

// helpers
import { getToasterOptions } from '../../../helpers/toaster';

// api
import {
  updateProjectsOrder,
  deleteProject,
  updateProject,
} from '../../../api/projects';

// types
import { DragItem, IStudioProjectCard } from '../../../types/studio.types';

// helpers
import { STUDIO_PROJECTS_CARD } from '../../../helpers/constants';
import { downloadImage } from '../../../helpers/image-download';

// hooks
import useClickOutside from '../../../hooks/useClickOutside';

// styles
import '../../../styles/studio/studio-content.scss';

interface IRenameFormValue {
  name: string;
}

const ProjectCard = ({
  index,
  id,
  project,
  moveCard,
  projects,
  onDeleteProject,
  onProjectDuplicate,
  onProjectRename,
  checkIsProjectCardDragging,
  isProjectDuplicateLoading,
  rightClickedProject,
  onSelectRightClickedProject,
}: IStudioProjectCard) => {
  const cardRef = useRef<HTMLDivElement>(null);

  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [contextMenuXYPosition, setContextMenuXYPosition] = useState({
    x: 0,
    y: 0,
  });
  const [isProjectRenameModalShown, setIsProjectRenameModalShown] =
    useState(false);
  const [isProjectDeleteModalShown, setIsProjectDeleteModalShown] =
    useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IRenameFormValue>();

  const navigate = useNavigate();

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: STUDIO_PROJECTS_CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem) {
      if (!cardRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // if to reorder projects while dragging should be any conditions - put it below

      // Determine rectangle on screen
      // const hoverBoundingRect = cardRef.current?.getBoundingClientRect();
      // Get vertical middle
      // const hoverMiddleY =
      //   (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      // const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      // const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return;
      // }
      // // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }

      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    drop() {
      const projectsWithOrder = projects?.map((innerProject, innerIndex) => ({
        id: innerProject.id,
        order: innerIndex,
      }));

      updateProjectsOrder(projectsWithOrder).catch(() => {
        toast.error(
          'Ooooops, error occurred with keeping projects order',
          getToasterOptions(),
        );
      });
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: STUDIO_PROJECTS_CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    checkIsProjectCardDragging(isDragging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const handleRightMouseClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsContextMenuVisible(false);

    const positionChange = {
      x: event.pageX,
      y: event.pageY,
    };

    setContextMenuXYPosition(positionChange);
    setIsContextMenuVisible(true);
    onSelectRightClickedProject(project.id);
  };

  const onProjectEdit = () => {
    navigate({
      pathname: `/tool/scene/${project.digitalGood}/`,
      search: `?redirectedFromStudio=1`,
    });
  };

  const onProjectDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.detail == 2) {
      onProjectEdit();
    }
  };

  const handleDeleteProject = async () => {
    try {
      await deleteProject(project.id);
      onDeleteProject(project.id);
    } catch (error) {
      toast.error(
        'Ooooops, error occurred with deleting project',
        getToasterOptions(),
      );
    }
  };

  const handleDuplicateProject = () => {
    onProjectDuplicate(project.id);
    setIsContextMenuVisible(false);
    onSelectRightClickedProject(null);
  };

  const openProjectRenameModal = () => {
    setIsProjectRenameModalShown(true);
    setIsContextMenuVisible(false);
    onSelectRightClickedProject(null);
  };

  const closeProjectRenameModal = () => {
    setIsProjectRenameModalShown(false);
  };

  const onRenameFormSubmit = async (formValues: IRenameFormValue) => {
    try {
      const updatedProject = await updateProject({
        id: project.id,
        ...formValues,
      });
      onProjectRename(updatedProject);
      setIsProjectRenameModalShown(false);
    } catch {
      toast.error(
        'Ooooops, error occurred with renaming project',
        getToasterOptions(),
      );
    }
  };

  const openProjectDeleteModal = () => {
    setIsProjectDeleteModalShown(true);
    setIsContextMenuVisible(false);
    onSelectRightClickedProject(null);
  };

  const closeProjectDeleteModal = () => {
    setIsProjectDeleteModalShown(false);
  };

  const onProjectExport = () => {
    downloadImage(project.preview);
  };

  const styles = {
    opacity: isDragging ? 0.2 : 1,
    border: isDragging ? '1px solid #fff' : 'none',
    borderRadius: isDragging ? '8px' : 0,
  };
  drag(drop(cardRef));

  useClickOutside(
    cardRef,
    () => {
      setIsContextMenuVisible(false);
    },
    'context-menu',
  );

  useEffect(() => {
    if (!isContextMenuVisible) {
      onSelectRightClickedProject(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContextMenuVisible]);

  return (
    <>
      <div
        ref={cardRef}
        style={styles}
        data-handler-id={handlerId}
        className={cn('studio-project-item', {
          hovered: project.id === rightClickedProject,
        })}
        onContextMenu={handleRightMouseClick}
        onClick={(event) => onProjectDoubleClick(event)}
      >
        <div className="studio-project-item--image-container">
          <img
            src={project.previewThumb}
            alt="project"
            className="studio-project-item--image-container--image"
          />
        </div>
        <p className="studio-project-item--title">{project.name}</p>
      </div>
      <ProjectContextMenu
        isShown={isContextMenuVisible}
        position={contextMenuXYPosition}
        onProjectEdit={onProjectEdit}
        onProjectDuplicate={handleDuplicateProject}
        openProjectRenameModal={openProjectRenameModal}
        openProjectDeleteModal={openProjectDeleteModal}
        onProjectExport={onProjectExport}
        isProjectDuplicateLoading={isProjectDuplicateLoading}
      />
      <Modal
        isOpen={isProjectRenameModalShown}
        ariaHideApp={false}
        onRequestClose={closeProjectRenameModal}
        className="Modal__Content dark"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <div className="studio-project-modal">
          <p className="studio-project-modal--label">
            Confirm renaming of design?
          </p>
          <form className="form" onSubmit={handleSubmit(onRenameFormSubmit)}>
            <input
              type="text"
              className="studio-project-modal--input"
              placeholder="Type project name here..."
              {...register('name', {
                required: true,
                value: project.name,
              })}
            />
            {errors.name && (
              <span className="field-error">Field is required</span>
            )}
            <div className="studio-project-modal--btns-container">
              <button
                className="studio-project-modal--btns-container--btn cancel"
                type="button"
                onClick={closeProjectRenameModal}
              >
                cancel
              </button>
              <button
                className="studio-project-modal--btns-container--btn submit"
                type="submit"
              >
                rename
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={isProjectDeleteModalShown}
        ariaHideApp={false}
        onRequestClose={closeProjectDeleteModal}
        className="Modal__Content dark"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <div className="studio-project-modal">
          <p className="studio-project-modal--label">
            Delete this design project
          </p>
          <p className="studio-project-modal--info">
            Are you sure you want to delete this project?
          </p>
          <div className="studio-project-modal--btns-container">
            <button
              className="studio-project-modal--btns-container--btn cancel"
              type="button"
              onClick={closeProjectDeleteModal}
            >
              cancel
            </button>
            <button
              className="studio-project-modal--btns-container--btn submit"
              type="button"
              onClick={handleDeleteProject}
            >
              delete forever
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProjectCard;
